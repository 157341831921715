import React, { useState, useEffect, useRef } from 'react'
//custom imports
import styles from '../../../assets/stylesheets/pages/project/project.module.scss'
import Heading from '../../../uicomponents/heading'
import FutworkButton from '../../../uicomponents/button'
import Popup from '../../../uicomponents/popup'
import Panel from '../../../uicomponents/panel'
import { RefreshIcon, AnswerIcon, AttachmentIcon, InfoIcon, YellowStarIcon } from '../../../assets/images'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import capitalise from "../../../helper/Capitalise";
import { getQmfscorerulebook, getTeletaskCallHistory, getTeleProject, submitQmfScore } from './service'
import useApi from '../../../hooks/useApi'
import ThreeDotLoader from '../../../components/utilities/ThreeDotLoader'
import ApiActions from "../../../actions/ApiActions";
import ProjectTitle from '../../../components/common/ProjectTitle';
import Dropdown from 'react-dropdown';
import { ToastContainer, toast } from "react-toastify";
import CustomTooltip from '../../../components/utilities/ToolTip'
import handleErrorMessage from '../../../helper/handleErrorMessage'

const sampleObjAuditResult = {
    "score": null,
    "weightage": 0,
    "percentage": 0,
    "verdict": "",
    "fatalCount": 0
}
const resultOptions = [
    { label: 'YES', value: 'Yes' },
    { label: 'NO', value: 'No' },
    { label: 'NA', value: 'NA' }
]
const QmfForm = (props) => {
    const {state: {query, outcomeQuery}} = props.location
    const searchBy = query
    if(!props.location.state){
        props.history.push({
            pathname: `/audit/v2`,
        })
        return true
    }

    const {
        name: callerName, title: projectTitle, outcomes: allOutcomes, teleprojectId, userId, mobile: callerMobile } = props.location.state.callerDetails
    const {
        recordingUrl,
        responses: dataCollected,
        data: customerInfo,
        disconnectedBy, outcome,
        _id: teletaskHistoryId
    } = props.location.state.callDetails

    const getQmfscorerulebookApi = useApi(getQmfscorerulebook)
    const getTeletaskCallHistoryApi = useApi(getTeletaskCallHistory)
    const getTeleProjectApi = useApi(getTeleProject)
    const submitQmfScoreApi = useApi(submitQmfScore)

    const [qmfScoreRules, setQmfScoreRules] = useState('')
    const [qmfCategories, setQmfCategories] = useState('')
    const [showFeedback, setShowFeedback] = useState(false)
    const [selectedRowData, setSelectedRowData] = useState('')
    const [showOutcome, setShowOutcome] = useState(false)
    const [showScript, setShowScript] = useState(false)
    const [showAudit, setShowAudit] = useState(false)
    const [auditResult, setAuditResult] = useState(sampleObjAuditResult)
    const [correctOutcome, setCorrectOutcome] = useState('')
    const [correctLanguage, setCorrectLanguage] = useState('')

    let inputFeedback = useRef('')


    useEffect(() => {
        getQmfscorerulebookApi.request()
        getTeleProjectApi.request(teleprojectId)
        getTeletaskCallHistoryApi.request({ teletaskHistory: teletaskHistoryId })
    }, [])


    const copyToClipboard = (recordingUrl) => {
        navigator.clipboard.writeText(recordingUrl);
    }

    const renderDataCollected = () => {
        return dataCollected && dataCollected.length > 0 ? dataCollected.map(data => {
            return <div className={styles.data_collected}>
                <div className={styles.qa_ans}>
                    <p className={styles.qa}>Q: {data ? data.question : 'NA'}</p>
                    <p><img src={AnswerIcon} /> {data ? data.answer : 'NA'}</p>
                </div>
            </div>
        }) : <div> ⚠️ No Data Collected</div>
    }

    const renderCallHistory = () => {
        return callHistoryLoading ? <ThreeDotLoader /> :
            callHistory && callHistory.length > 0 ? callHistory.map(({ outcome, attempts, recordingUrl }) => {
                return <div className={styles.call_history}>
                    <span className={styles.outcome}>Attempt {attempts || 'NA'}: {outcome ? outcome.title : 'NA'}</span>
                    <audio controls src={recordingUrl || ''} />
                </div>
            }) : <div className={styles.call_history}>⚠️ No call history present</div>
    }

    const renderCustomerInfo = () => {
        return <div className={styles.customer_info}>
            {
                customerInfo ? Object.entries(customerInfo).map(
                    ([key, value], idx) => (
                        <div className={styles.info} key={idx + 1}>
                            {`${capitalise(key)}: ${value}`}
                        </div>
                    )
                ) : <div>⚠️ No Customer Data</div>
            }
        </div>
    }

    const renderFeedback = () => {
        return (
            <Popup
                show={showFeedback}
                closePopup={() => {
                    if(!selectedRowData.feedback){
                        let updateScore = qmfScoreRules && qmfScoreRules.map(i => {
                            if (i.sequence == selectedRowData.sequence) {
                                i.result = 'Yes'
                                i.score = i.weightage
                            }
                            return i
                        })
                        setQmfScoreRules(updateScore)
                    }
                    setSelectedRowData('')
                    setShowFeedback(false)
                }}
                size='sm'
                heading={`${selectedRowData && selectedRowData.feedback ? 'Edit' : 'Add'} feedback`}
            >
                <div className={styles.param_feedback}>
                    <div className={styles.param_detail}>
                        <div>Category: {selectedRowData && selectedRowData.category} </div>
                        <div>Parameter: {selectedRowData && selectedRowData.behaviour}</div>
                    </div>
                    <div className={styles.add_feedback}>
                        <textarea  ref={inputFeedback} className={styles.feedback_for_param} 
                        id='fedbk' defaultValue={selectedRowData && selectedRowData.feedback} />
                    </div>
                    <div className={styles.button_row}>
                    <FutworkButton
                        buttonSize='sm'
                        buttonStyle='primary--outline'
                        onClick={() => {
                            let inputText = `${inputFeedback.current.value} [00:00]`
                            inputFeedback.current.value = inputText
                            let inputFdbk = document.getElementById('fedbk')
                            inputFdbk.focus()
                            inputFdbk.setSelectionRange(inputText.length, inputText.length);
                        }}
                    >+Add Timestamp</FutworkButton>
                    <FutworkButton
                        buttonSize='sm'
                        onClick={() => addEditFeedback(selectedRowData)}
                    >Save</FutworkButton>
                    </div>
                </div>
            </Popup>
        )
    }

    const renderOutcome = () => {
        return (
            <Popup
                show={showOutcome}
                closePopup={() => {
                    setShowOutcome(false)
                }}
                size='lg'
                heading='Outcomes'
            >
                <>
                    {
                        allOutcomes && allOutcomes.length > 0 ? allOutcomes.map(({ title, description, isWinning }) => {
                            return <div className={styles.outcomes}>
                                <div className={styles.outcome_title}>{title} {isWinning && <img src={YellowStarIcon} />}</div>
                                <div>{description}</div>
                            </div>
                        }) : <div className={styles.outcomes}>⚠️ No outcomes present</div>
                    }
                </>
            </Popup>
        )
    }

    const renderScript = () => {
        const { scriptDocuments } = teleproject
        return (
            <Popup
                show={showScript}
                closePopup={() => {
                    setShowScript(false)
                }}
                size='sm'
                heading='Script documents'
            >
                {teleprojectLoading ? <ThreeDotLoader /> : <div className={styles.scripts}>
                    {
                        scriptDocuments && scriptDocuments.length > 0 ?
                            scriptDocuments.map(({ title, url }) =>
                                <p className={styles.row}>{title} <a href={url} target="_blank" rel="noopener noreferrer"> Script Link</a></p>
                            ) : <p>⚠️ No scripts are attached</p>
                    }
                </div>}
            </Popup>
        )
    }


    const renderResult = () => {
        let remarks = ''
        let statusChange = ''

        const goBack = () => {
            setShowAudit(false)
            if(auditSubmitError){
                return false
            }
            props.history.push({
                pathname: `/audit/v2/qmf/calls-list/${userId}`,
                state: {
                    callerDetails: props.location.state.callerDetails,
                    activeTab: props.location.state.activeTab,
                    page: props && props.location && props.location.state && props.location.state.page ? 
                    props.location.state.page : undefined,
                    callPage: props && props.location && props.location.state && props.location.state.callPage ? 
                    props.location.state.callPage : undefined,
                    query: searchBy,
                    outcomeQuery: outcomeQuery
                }
            })
        }

        if (auditSubmitResult) {
            const { qmfScoreCard: { percentage }, verdict } = auditSubmitResult
            if (verdict == 'failed') {
                remarks = `The verdict is failed, the percentage score was ${percentage}%`
                statusChange = `Audit Result: Quality level low - caller has been moved to retraining`
            } else {
                if (percentage < 85) {
                    remarks = `The score achieved is ${percentage}%, hence no futher audits can be done for the caller till the retraining is completed`
                    statusChange = `Audit Result: Quality level low - caller has been moved to retraining`
                } else {
                    remarks = `The score achieved is ${percentage}%, hence the caller has successfully passed the QMF`
                    statusChange = `Audit Result: Quality level passed successfully`
                }
            }
        }

        const Content = () => {
            return <div className={styles.audit_result}>
                <div className={styles.status}>
                    <span>{statusChange}</span>
                </div>
                <div className={styles.caller_info}>
                    <div className={styles.row}>
                        <span>Caller name: </span>
                        <span>{callerName || 'NA'}</span>
                    </div>
                    <div className={styles.row}>
                        <span>Project: </span>
                        <span>{projectTitle || 'NA'}</span>
                    </div>
                    <div className={styles.row}>
                        <span>Mobile No: </span>
                        <span>{callerMobile || 'NA'}</span>
                    </div>
                </div>
                {/* <div className={styles.remarks}>
                    <span>{remarks || 'NA'}</span>
                </div> */}
            </div>
        }

        const ActionBtns = () => {
            return <FutworkButton buttonSize='sm'
                onClick={goBack}
            >OK</FutworkButton>
        }

        return (
            <Popup
                show={showAudit}
                closePopup={goBack}
                size='sm'
                heading=''
                popupContent={auditSubmitLoading ? <ThreeDotLoader/> : <Content />}
                popupFooter={auditSubmitLoading ? null : <ActionBtns />}
                footerAlign='left'
            />
        )
    }

    const handlePopups = () => {
        if (showFeedback) return renderFeedback()
        if (showOutcome) return renderOutcome()
        if (showScript) return renderScript()
        if (showAudit) return renderResult()
        else
            return null
    }

    const mutatRuleBook = (rules) => {
        const { id } = rules
        let setOfRules = rules[id]
        setQmfCategories([...new Set(rules[id].map(rule => rule.category))])
        setQmfScoreRules(setOfRules.map(rule => {
            return {
                ...rule,
                "result": "Yes",
                "score": rule.weightage,
                "feedback": "",
                "description": rule.description
            }
        }))
    }

    let { data } = getQmfscorerulebookApi || {}
    const { loading, error: ruleBookError } = getQmfscorerulebookApi

    const { data: callHistory, loading: callHistoryLoading, error: teletaskHistoryError } = getTeletaskCallHistoryApi || {}

    const { data: teleproject, loading: teleprojectLoading, error: teleprojectError } = getTeleProjectApi || {}

    let { data: auditSubmitResult } = submitQmfScoreApi.data || {}
    const { error: auditSubmitError, loading: auditSubmitLoading } = submitQmfScoreApi || {}

    useEffect(() => {
        if (data && Object.keys(data).length > 0) {
            mutatRuleBook(data)
        }
    }, [data])

    //api error handling
    useEffect(() => {
        if (teletaskHistoryError)
            toast(handleErrorMessage(teletaskHistoryError))
    }, [teletaskHistoryError])
    useEffect(() => {
        if (ruleBookError)
            toast(handleErrorMessage(ruleBookError))
    }, [ruleBookError])
    useEffect(() => {
        if (teleprojectError)
            toast(handleErrorMessage(teleprojectError))
    }, [teleprojectError])
    useEffect(() => {
        if (auditSubmitError)
            toast(handleErrorMessage(auditSubmitError))
    }, [auditSubmitError])


    const changeResultOfBehavior = (behavior, value) => {
        setAuditResult(sampleObjAuditResult)
        let updateScore = ''
        if (value == 'No' || value == 'NA') {
            updateScore = qmfScoreRules && qmfScoreRules.map(i => {
                if (i.sequence == behavior.sequence) {
                    i.result = value
                    i.score = 0
                }
                return i
            })
            setQmfScoreRules(updateScore)
            setSelectedRowData(behavior);
            if(value === 'No'){
                setShowFeedback(true)
            }
        } else {
            updateScore = qmfScoreRules && qmfScoreRules.map(i => {
                if (i.sequence == behavior.sequence) {
                    i.result = value
                    i.score = i.weightage
                    i.feedback = ''
                }
                return i
            })
            setQmfScoreRules(updateScore)
        }
    }

    const addEditFeedback = (behavior) => {
        if(inputFeedback.current.value == ''){
            return true
        }
        let updateFeedback = ''
        updateFeedback = qmfScoreRules && qmfScoreRules.map(i => {
            if (i.sequence == behavior.sequence) {
                i.feedback = inputFeedback.current.value
            }
            return i
        })
        setQmfScoreRules(updateFeedback)
        setSelectedRowData('')
        setShowFeedback(false)
    }

    const totalWeigtage = (item) => {
        let totalWeight = 0
        totalWeight = qmfScoreRules && qmfScoreRules.filter((it) => it.category == item).reduce((acc, curr) => acc + curr.weightage, 0)
        return <span>{totalWeight}</span>
    }

    const auditScore = async () => {
        const payload = {
            "qmfGrades": qmfScoreRules && qmfScoreRules.sort((a, b) => a.sequence - b.sequence)
        }
        try {
            let result = await ApiActions.calculateQmfScore(payload)
            let updateResult = { ...auditResult }
            Object.entries(result.data).map(([key, value]) => {
                updateResult[key] = value
            })
            setAuditResult(updateResult)
        } catch (error) {
            console.error(error)
            let msg = ''
            if (error.response)
                msg = error.response && error.response.data || "Unexpected Error!"
            else
                msg = error.message || "Unexpected Error!"
            toast(handleErrorMessage(msg))
        }
    }

    const submitAudit = async () => {
        if(!correctLanguage & !correctOutcome){
            toast('Please select the correct outcome & call language')
            return true
        }
        if(!correctOutcome){
            toast('Please select the correct outcome')
            return true
        }
        if (!correctLanguage) {
            toast('Please select the call language')
            return true
        }
        const payload = {
            userId,
            teleprojectId,
            "teletaskhistoryId": teletaskHistoryId,
            "auditType": "performanceAudit",
            "callLanguage": correctLanguage && correctLanguage.name ?
                correctLanguage.name : '',
            "qmfGrades": qmfScoreRules && qmfScoreRules.sort((a, b) => a.sequence - b.sequence).map(rule => {
                delete rule.description
                return rule
            }),
            "outcome": correctOutcome || {}
        }
        submitQmfScoreApi.request(payload)
        setShowAudit(true)
    }

    return (
        <div className={styles.qmf_form_page_wrapper}>
            <header className={styles.qmf_form_header}>
                <ProjectTitle title={
                    <ul className={styles.breadcrumbs}>
                        <li>QMF</li>
                        <li>{projectTitle || 'NA'}</li>
                        <li>{callerName || 'NA'}</li>
                    </ul>
                }
                    goBack={() => props.history.push({
                        pathname: `/audit/v2/qmf/calls-list/${userId}`,
                        state: {
                            callerDetails: props.location.state.callerDetails,
                            activeTab: props.location.state.activeTab,
                            page: props && props.location && props.location.state && props.location.state.page ? 
                            props.location.state.page : undefined,
                            callPage: props && props.location && props.location.state && props.location.state.callPage ? 
                            props.location.state.callPage : undefined,
                            query: searchBy,
                            outcomeQuery: outcomeQuery
                        }
                    })} />
                <div className={styles.button_links}>
                    <FutworkButton
                        buttonSize='x-large'
                        onClick={() => setShowOutcome(true)}
                    >View Outcomes</FutworkButton>
                    <FutworkButton
                        buttonSize='x-large'
                        onClick={() => setShowScript(true)}
                    >View Script</FutworkButton>
                </div>
            </header>
            <div className={styles.flex_container}>
                <aside className={styles.sidebar}>
                    <Heading text={'Call Details'} />

                    <div className={styles.call_recording}>
                        <div className={styles.call_recording_row}>
                        <span className={styles.sub_heading}>Call recording</span>
                        <span className={styles.link} onClick={() => copyToClipboard(recordingUrl)}>Copy audio link</span>
                        </div>
                        <div className={styles.audio_copy_link}>
                            <audio controls src={recordingUrl} />
                        </div>
                    </div>

                    <div className={styles.panel_collection}>
                        <Panel title={<span className={styles.sub_heading}>Data collected</span>}>
                            {renderDataCollected()}
                        </Panel>
                        <Panel title={<span className={styles.sub_heading}>Call History</span>}>
                            {renderCallHistory()}
                        </Panel>
                        <Panel title={<span className={styles.sub_heading}>Customer Info</span>}>
                            {renderCustomerInfo()}
                        </Panel>
                    </div>

                    <div className={styles.call_details}>
                        {/* <div className={styles.audio_copy_link}>
                            <audio controls src={recordingUrl} />
                        </div>
                        <span className={styles.link} onClick={() => copyToClipboard(recordingUrl)}>Copy audio link</span> */}
                        <div className={styles.sub_heading}>Disconnected by</div>
                        <input type='text' readOnly defaultValue={
                            (disconnectedBy == 'Callee' ? 'Customer' : disconnectedBy == 'Caller' ? 'Tele-caller' : disconnectedBy) || 'NA'} />
                        <div className={styles.sub_heading}>Outcome marked</div>
                        <input type='text' readOnly defaultValue={outcome && outcome.title} />
                        <div className={styles.sub_heading}>Correct outcome</div>

                        <Dropdown
                            options={allOutcomes && allOutcomes.length > 0 ? allOutcomes.map(({ title }) => title) : []}
                            onChange={(e) => setCorrectOutcome(allOutcomes.find(({ title }) => title == e.value))}
                            placeholder="Select an outcome"
                            className={styles['qmf_dropdown']}
                        />

                        <div className={styles.sub_heading}>Call language</div>
                        <Dropdown
                            options={teleproject && teleproject.languages && teleproject.languages.length > 0
                                ? teleproject.languages.map(({ name }) => name) : []}
                            onChange={(e) => setCorrectLanguage(teleproject.languages.find(({ name }) => name == e.value))}
                            placeholder="Select call language"
                            className={styles['qmf_dropdown']}
                        />
                    </div>



                </aside>

                {
                    loading ?
                        <div style={{ display: 'flex', width: '70%', justifyContent: 'center', alignItems: 'center' }}>
                            <ThreeDotLoader />
                        </div>
                        :
                        <main className={styles.main_content}>
                            <div className={styles.main_content_header}>
                                <Heading text={'Score card'} />
                            </div>
                            {
                                qmfCategories && qmfCategories.length > 0 && qmfCategories.map(item => {
                                    return (
                                        <>
                                            <div className={styles['horizontal-divider-new']}></div>
                                            <div className={styles['çategory-section']}>
                                                <p className={styles['category-name']}>{item} [ {totalWeigtage(item)} ]</p>
                                                <div className={styles['grid-layout']}>
                                                    <div className={styles['parameter']}>Parameters</div>
                                                    <div className={styles['parameter']}>Result</div>
                                                    <div className={styles['parameter']}>Weightage</div>
                                                    <div className={styles['parameter']}>Score</div>
                                                    <div className={styles['parameter']}>Feedback</div>
                                                </div>
                                                {
                                                    qmfScoreRules && qmfScoreRules.length > 0 && qmfScoreRules.map((i, idx) => {
                                                        if (i.category == item) {
                                                            return <div className={styles['grid-layout']} key={idx + 1}>
                                                                <div className={i.isFatal ? [styles['param-name'], styles['fatal']].join(' ') : styles['param-name']}>{i && i.behaviour}
                                                                <CustomTooltip placement={'bottom'} description={i.description}
                                                                component={<img src={InfoIcon} alt={i.description} />} />
                                                                </div>
                                                                <div>
                                                                    <select name="result" id={`param-result-${idx + 1}`} onChange={(e) => changeResultOfBehavior(i, e.target.value)}
                                                                        value={i && i.result}>
                                                                        {
                                                                            resultOptions.map((option => {
                                                                                return <option value={option.value}
                                                                                    hidden={i.isFatal && option.label == 'NA'} key={option.value}>{option.label}</option>
                                                                            }))
                                                                        }
                                                                    </select>

                                                                </div>
                                                                <div className={i && i.isFatal ? [styles['score'], styles['fatal']].join(' ') : styles['score']}>{i && i.isFatal ? 'FATAL' : i && i.weightage}</div>
                                                                <div className={styles['score']}>{i && i.isFatal ? '--' : i && i.score}</div>
                                                                <div className={i && (i.result == 'Yes' || i.result == 'NA') ? [styles['score'], styles['disable_feedback'], styles['btn']].join(' ') : [styles['score'], styles['btn']].join(' ')} onClick={() => {
                                                                    if (i && i.result == 'No') {
                                                                        setSelectedRowData(i);
                                                                        setShowFeedback(true)
                                                                    }
                                                                }}>
                                                                    <FontAwesomeIcon icon={i.feedback ? faPenToSquare : faPlus} className={styles['plus']} />
                                                                </div>
                                                            </div>
                                                        }
                                                    })
                                                }
                                            </div>
                                        </>
                                    )
                                })
                            }
                            <div className={styles['horizontal-divider-new']}></div>
                            <div className={styles['audit_footer']}>
                                <div className={styles['audit_score']}>
                                    <span className={styles['label']}>Audit score</span>
                                    <div className={[styles['score'], styles['btn']].join(' ')} onClick={auditScore}>
                                        {auditResult && auditResult.score == null ?
                                            <img src={RefreshIcon} width='16px' alt='refresh-score' /> : auditResult && auditResult.score}
                                    </div>
                                    <span className={styles['label']}>Audit verdict</span>
                                    <div className={styles['score']}>{auditResult && auditResult.verdict == '' ? '--' : auditResult && auditResult.verdict && auditResult.verdict.toUpperCase()}</div>
                                </div>
                                <div className={styles['button-section']}>
                                    <FutworkButton buttonSize='medium w-100' buttonStyle='success--solid'
                                        disabled={auditResult && auditResult.score == null ? true : false}
                                        onClick={submitAudit}
                                    >SUBMIT</FutworkButton>
                                </div>
                            </div>
                            <div className={styles['horizontal-divider-new']}></div>
                        </main>
                }

            </div>
            <ToastContainer draggable={true} />
            {handlePopups()}
        </div>
    )
}

export default QmfForm