import React from 'react';
import ApiActions from '../../../actions/ApiActions';
import Switch from '../../utilities/Switch';
import FrappModal from '../../utilities/FrappModal';

import FormGroup from 'react-bootstrap/lib/FormGroup';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import FormControl from 'react-bootstrap/lib/FormControl';
import FrappButton from '../../utilities/FrappButton';
import SearchInput from '../../utilities/SearchInput';
import pick from 'lodash.pick';
import ProjectTitle from '../../common/ProjectTitle'

class WildCardTool extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showTargeting: false,
            targetingFormData: {
                collegeList: [],
                gender: [],
                platform: [],

                cityList: []
            },
            formData: {
                active: true
            },
            wildcard: [],
            skip: 0

        };
    }

    componentDidMount() {
        //pre populate the targeting fields and toggle tageting switch
        this.fetchData();
        this.listenToScroll();
    }
    listenToScroll = () => {
        document.addEventListener("scroll", this.checkDataToBeLoaded);
    };
    checkDataToBeLoaded = () => {
        var scrolled = window.scrollY;
        var documentHeight = document.body.scrollHeight;
        var initialWindowHeight = window.innerHeight;
        var fetchingLazy = this.state.fetchingLazy;
        var skip = this.state.skip;

        const cond3 = scrolled > 0;
        //const cond2 = !fetchingLazy;

        const cond4 = scrolled + initialWindowHeight >= documentHeight - 300;
        if (cond4 && cond3) {
            this.setState({ fetchingLazy: true }, this.fetchWildCard);
        }
    };

    fetchWildCard = () => {
        this.fetchData();
    }
    fetchData = () => {
        let skip = this.state.skip;
        const setState = this.setState.bind(this);
        let wildcard = this.state.wildcard;
        ApiActions.getData("/wildcard", {
            _vortex: true,
            skip,
            limit: 50,
            where: {
                "userRelated": { "$nin": [true] }
            },
            sort: { "createdAt": -1 },

        })
            .then(resp => {
                var newList = wildcard.concat(resp);
                skip = skip + 50;

                this.setState({ wildcard: newList, skip })
            }).then(setState);
    }

    render() {
        return (
            <div className='tools'>
                <div className='container'>
                <div className="wildcard-tool">
                <ProjectTitle title={`Wild Card Tool`} goBack={() => this.props.history.push("/tools")} />
                {this.renderList()}
                {this.renderForm()}
            </div>
            </div>
            </div>
        )
    }

    renderForm = () => {
        let { formData, showTargeting } = this.state;

        return (
            <FrappModal className="wildcard-form-modal" ref={(c) => this.modal = c} >
                <h3>{formData.id ? 'Edit' : 'Add'} Wildcard</h3>
                <form onSubmit={this.submitWildCard} >
                    <FormGroup>
                        <ControlLabel>External</ControlLabel>
                        <FormControl value={formData.external || ""} onChange={this.setFormData} name="external" type="url" placeholder="Enter the link where you want send the students" />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>App Banner</ControlLabel>
                        <FormControl value={formData.image || ""} onChange={this.setFormData} name="image" required type="url" placeholder="Banner image for the app" />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>Web Banner</ControlLabel>
                        <FormControl value={formData.webImage || ""} onChange={this.setFormData} name="webImage" required type="url" placeholder="Banner image for the web" />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>Deeplink</ControlLabel>
                        <FormControl value={formData.deeplink || ""} onChange={this.setFormData} name="deeplink" type="url" placeholder="Enter deeplink if any" />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>Featured Position</ControlLabel>
                        <FormControl value={formData.featured || ""} onChange={this.setFormData} name="featured" type="number" placeholder="Enter position of the card in the list" />
                    </FormGroup>
                    <div><h4>targeting&nbsp;&nbsp;&nbsp;<Switch onToggle={this.setShowTargetingData} checked={showTargeting} name="active" /></h4></div>
                    {showTargeting ? this.renderWildCardTargeting() : null}
                    <FrappButton type="submit" className="submit" ref={(c) => this.submitBtn = c} >Submit</FrappButton>
                </form>
            </FrappModal>
        )
    }

    submitWildCard = (e) => {
        e.preventDefault();
        let { showTargeting, targetingFormData, formData } = this.state;
        var targetingData = {};
        var count = Object.values(targetingFormData).filter(x => x.length > 0)
        if (showTargeting && count.length > 0) {
            if (targetingFormData.collegeList.length > 0) {
                targetingData.colleges = targetingFormData.collegeList.map(s => s.id);
            }
            if (targetingFormData.cityList.length > 0) {
                targetingData.cities = targetingFormData.cityList.map(s => s.toLowerCase() || s.toLowerCase());
            }
            if (targetingFormData.gender.length > 0) {
                targetingData.gender = targetingFormData.gender;
            }
            if (targetingFormData.platform.length > 0) {
                targetingData.platform = targetingFormData.platform;
            }
            //console.log("with targeting data", { ...formData, ...targetingData })
            this.postWildCard({ ...formData, ...targetingData })
        } else {
            //console.log("data", this.state.formData)
            this.postWildCard(this.state.formData)
        }

    }
    renderCollegeInputGroup = () => {
        var data = {
            placeholder: "Search for the college for Targeting",
            getData: (params) => ApiActions.getData('/college/search', params),
            searchField: ["name"],
            returnValueField: "id",
            render: (obj) => <React.Fragment>{obj.name} &mdash; {obj.city}</React.Fragment>,
            callback: this.updateCollegeList,
            customParams: { searchable: true }
        }

        return (
            <FormGroup className="college-input-wrapper" >
                <ControlLabel className="input-header" >Search the colleges to Target</ControlLabel>
                {this.renderCollegeList()}
                <SearchInput ref={(c) => this.searchInput = c} data={data} />
                {/* <div className="or">OR</div>
                <FormControl rows="5" componentClass="textarea" type="text" value={this.state.targetingFormData.colleges} onChange={this.setData} name="colleges" /> */}
            </FormGroup>
        )
    }

    renderCollegeList = () => {
        var { collegeList } = this.state.targetingFormData;

        if (collegeList.length === 0) {
            return null
        }
        return (
            <div className="college-list" >
                {collegeList.map(college => (
                    <div key={college.id} className="college-item" >
                        <span>{college.name}</span>
                        <img onClick={() => this.removeCollege(college.id)} src={require("../../../assets/images/close.svg")} alt="" />
                    </div>
                ))}
            </div>
        )
    }
    renderGenderInputGroup = () => {
        return (
            <FormGroup className="gender-input-wrapper" >
                <ControlLabel className="input-header" >Target Gender</ControlLabel>
                <div className="input-container" >
                    {this.renderGenderInput({ id: "male" })}
                    {this.renderGenderInput({ id: "female" })}
                </div>
            </FormGroup>
        )
    }

    renderGenderInput = (data) => {
        var { gender } = this.state.targetingFormData;

        return (
            <label htmlFor={data.id} >
                <div className={`icon-wrapper ${data.id} ${gender.indexOf(data.id) >= 0 ? "checked" : ""}`} >
                </div>
                <input
                    type="checkbox"
                    tabIndex="-1"
                    checked={gender.indexOf(data.id) >= 0}
                    id={data.id} name={"gender"} value={data.id} className="hidden" onChange={this.setData} />
            </label>
        )
    }

    renderPlatformInputGroup = () => {
        return (
            <FormGroup className="platform-input-wrapper" >
                <ControlLabel className="input-header" >Target Platform</ControlLabel>
                <div className="input-container" >
                    {this.renderPlatformInput({ id: "web" })}
                    {this.renderPlatformInput({ id: "android" })}
                    {this.renderPlatformInput({ id: "ios" })}
                </div>
            </FormGroup>
        )
    }

    renderPlatformInput = (data) => {
        var { platform } = this.state.targetingFormData;

        return (
            <label htmlFor={data.id} >
                <div
                    className={`icon-wrapper ${data.id} ${platform.indexOf(data.id) >= 0 ? "checked" : ""}`} >
                    <img className="tick" src={require("../../../assets/images/blue-tick.svg")} alt="" />

                </div>
                <input
                    type="checkbox"
                    tabIndex="-1"
                    checked={platform.indexOf(data.id) >= 0}
                    id={data.id} name={"platform"} value={data.id} className="hidden" onChange={this.setData} />

            </label>
        )
    }

    setData = (e) => {
        var { targetingFormData } = this.state;
        var { name, value } = e.target;

        if (name === 'colleges') {
            targetingFormData[name] = value;
        }
        else if (e.target.checked) {
            targetingFormData[name].push(value)
        } else {
            targetingFormData[name] = targetingFormData[name].filter(x => x !== value)
        }

        this.setState({ targetingFormData })
    }

    renderCityInputGroup = () => {

        var data = {
            placeholder: "Search for the city",
            getData: (params) => ApiActions.getData('/city', params),
            searchField: ["name", "alias_for"],
            returnValueField: "id",
            render: (obj) => <React.Fragment>{obj.name}</React.Fragment>,
            callback: this.updateCityList,
            extraParams: { _vortex: true }
        }

        return (
            <div className="city-input-group"  >
                <ControlLabel className="input-header" >Search the cities to Target</ControlLabel>
                {this.renderCityList()}
                <SearchInput ref={(c) => this.citySearchInput = c} data={data} />
            </div>
        )
    }

    renderCityList = () => {
        var { cityList } = this.state.targetingFormData;

        if (cityList.length === 0) {
            return null
        }

        return (
            <div className="city-list" >
                {cityList.map(city => (
                    <div key={city} className="college-item" >
                        <span>{city}</span>
                        <img onClick={() => this.removeCity(city)} src={require("../../../assets/images/close.svg")} alt="" />
                    </div>
                ))}
            </div>
        )
    }

    removeCity = (city) => {
        var { targetingFormData } = this.state;

        targetingFormData.cityList = targetingFormData.cityList.filter(s => s !== city);
        this.setState({ targetingFormData })
    }

    updateCityList = (searchValue, cityObj) => {
        var { targetingFormData } = this.state;


        targetingFormData.cityList.push(cityObj.alias_for || cityObj.name);
        this.setState({ targetingFormData }, () => {
            this.citySearchInput.setState({ searchValue: "", returnValue: null })
        })
    }


    renderWildCardTargeting = () => {
        return (
            <React.Fragment>
                <hr />
                {this.renderCollegeInputGroup()}
                {this.renderGenderInputGroup()}
                {this.renderPlatformInputGroup()}
                {this.renderCityInputGroup()}

            </React.Fragment>
        )
    }

    removeCollege = (id) => {
        var { targetingFormData } = this.state;
        targetingFormData.collegeList = targetingFormData.collegeList.filter(s => s.id !== id);
        this.setState({ targetingFormData })
    }

    updateCollegeList = (searchValue, collegeObj) => {
        var { targetingFormData } = this.state;

        targetingFormData.collegeList.push(collegeObj)
        this.setState({ targetingFormData }, () => {
            this.searchInput.setState({ searchValue: "", returnValue: null })
        })
    }

    renderList = () => {
        let { wildcard } = this.state;
        if (!wildcard) return;
        return (
            <div key={wildcard.id} className="list">
                {wildcard.map(this.renderCard)}
                {this.renderAddButton()}
            </div>
        )
    }

    renderAddButton = () => {
        return (
            <div onClick={() => { this.modal.toggle(true); this.setState({ formData: {} }) }} className="add-button">
                Add wilcard
            </div>
        )
    }

    renderCard = (wildcard) => {
        return (
            <div key={wildcard.id} className="wildcard-wrapper" >
                <div className={`wildcard ${wildcard.active ? '' : 'blur'}`} >
                    <img src={wildcard.image} alt="" />
                </div>
                <div className="actions">
                    <span>
                        <img onClick={() => { this.setWildCardData(wildcard) }} className="edit" src={require("../../../assets/images/edit-blue.svg")} alt="" />
                    </span>
                    <span>
                        <Switch onToggle={this.setActiveData} checked={wildcard.active} name="active" data-id={wildcard.id} />
                    </span>
                </div>
            </div>
        )
    }

    setWildCardData = (wildcard) => {

        var { targetingFormData } = this.state
        targetingFormData = {
            collegeList: [],
            gender: [],
            platform: [],
            cityList: []
        }
        if (wildcard.colleges && wildcard.colleges.length > 0) {
            let collegeList = wildcard.colleges;

            ApiActions.getData('college', {
                _vortex: true,
                where: { "id": { "$in": collegeList } }
            }).then(resp => {
                targetingFormData.collegeList = resp;
                this.setState({ targetingFormData })
            }).catch(
                //alert("something went wrong ! refresh the page tool")
            )
        }
        if (wildcard.cities && wildcard.cities.length > 0) {
            targetingFormData.cityList = wildcard.cities
        }
        if (wildcard.gender && wildcard.gender.length > 0) {
            targetingFormData.gender = wildcard.gender
        }
        if (wildcard.platform && wildcard.platform.length > 0) {
            targetingFormData.platform = wildcard.platform
        }
        let formData = pick(wildcard, ["active", "deeplink", "external", "featured", "id", "image", "webImage"])
        var count = Object.values(targetingFormData).filter(x => x.length > 0)
        if (count.length > 0) {
            this.setState({ formData: formData, targetingFormData: targetingFormData, showTargeting: true });
        } else {
            this.setState({
                formData: formData, targetingFormData: {
                    collegeList: [],
                    gender: [],
                    platform: [],
                    cityList: []
                }, showTargeting: false
            });
        }
        this.modal.toggle(true)
    }


    setShowTargetingData = (name) => {
        var { showTargeting } = this.state;
        showTargeting = !showTargeting;
        this.setState({ showTargeting })
    }

    setFormData = (e) => {
        let { formData } = this.state;
        let { name, value } = e.target;
        formData[name] = value.trim();
        this.setState({ formData })
    }

    setActiveData = (e) => {
        let { wildcard } = this.state;
        let { attributes } = e.target;
        let id = attributes['data-id'].value;
        let currentWildCard = wildcard.filter(w => w.id === id)[0];
        wildcard = wildcard.filter(w => w.id !== id);
        currentWildCard.active = !currentWildCard.active;
        wildcard.push(currentWildCard);
        this.setState({ wildcard }, () => this.postWildCard(currentWildCard));
    }

    postWildCard = (wildcard) => {
        //pre process the data before submission
        this.submitBtn && this.submitBtn.startLoading();
        if (wildcard.external === "")
            wildcard.external = null
        if (wildcard.id) {
            delete wildcard.updatedAt;
            delete wildcard.createdAt;
            ApiActions.putData(`/wildcard/${wildcard.id}`, { ...wildcard, _vortex: true })
                .then(this.formSuccess)
        } else {
            wildcard.active = true;
            ApiActions.postData("/wildcard", { ...wildcard, _vortex: true })
                .then(this.formSuccess)
        }
    }

    formSuccess = (newWildCard) => {
        this.modal.toggle(false);
        this.submitBtn && this.submitBtn.stopLoading();
        let { wildcard } = this.state;
        let ids = wildcard.map(s => s.id);
        if (ids.indexOf(newWildCard.id) >= 0) {
            wildcard = wildcard.filter(w => {
                if (w.id === newWildCard.id) return newWildCard;
                return w;
            })
        } else {
            wildcard.push(newWildCard)
        }

        this.setState({ wildcard })
        alert("Wildcard was updated successfully!")
    }
}


export default WildCardTool;