import React, {useState, useEffect} from 'react'
import ApiActions from '../../actions/ApiActions';
import { ToastContainer, toast } from "react-toastify";
import FormGroup from 'react-bootstrap/lib/FormGroup';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import FormControl from 'react-bootstrap/lib/FormControl';
import FrappButton from "../utilities/FrappButton";
import FrappModal from "../utilities/FrappModal";
import SMSTemplateForm from '../common/SMSTemplateForm'
import WhatsappTemplateForm from '../common/WhatsappTemplateForm';
import DropdownButton from "react-bootstrap/lib/DropdownButton";
import MenuItem from "react-bootstrap/lib/MenuItem";
import checkIsEmpty from '../../helper/checkIsEmpty'
import TruncateString from '../../helper/TruncateString'
import AlwaysScrollToBottom from '../common/AlwaysScrollToBottom'

const WhatsappTemplate = (props) => {
    let linkOutcomeToTemplateBtn;

    const dummyTemplate = {
        title: '',
        wa_from: '',
        wa_template_id: '',
        active: false,
        teleproject: props.match.params.id,
        tag: 'whatsapp'
    }
    const dummyoutcomeTemplateLink = {
        outcome: '',
        templateId: ''
    }

    const [loading, setLoading] = useState(true)
    const [formData, setFormData] = useState({})
    const [templates, setTemplates] = useState([dummyTemplate])
    const [outcomeTemplate, setOutcomeTemplate] = useState([])
    const [templateDropdownList, setTemplateDropdownList] = useState([])
    const [templateLatestCount, setTemplateLatestCount] = useState(0)
    const [showSmsTestUi, setShowSmsTestUi] = useState(false)
    const [currentSmsData, setCurrentSmsData] = useState({})
    const [checkIfLatest, setCheckIfLatest] = useState(false)

    useEffect(() => {
        let id = props.match.params.id
        ApiActions.getTeleProject(id).then(resp => {
            setFormData({ ...resp })
            if (resp.whatsapp)
                reArrangeOutcomeTemplate(resp.whatsapp)
        }).then(() => {
            getWhatsappTemplateList(id)
            setCheckIfLatest(true)
        }).catch(err => {
            console.error(err.response)
        })
    }, [])


    const getTeleprojectdata = async(id) => {
        try {
           let result = await ApiActions.getTeleProject(id)
           return result
        } catch (error) {
            return error
        }
    }

    const getWhatsappTemplateList = (id) => {
        ApiActions.findWhatsappTemplate(id).then(resp => {
            if (!checkIsEmpty(resp)) {
                setTemplateDropdownList([...resp])
                const filterData = resp.map((obj) => {
                    const { id, teleproject, title, active, brand, createdAt, updatedAt, ...rest } = obj
                    const remainparam = rest
                    return {
                        id,
                        teleproject,
                        title,
                        active,
                        isEdited: false,
                        ...remainparam
                    }
                })
                setTemplates([...filterData])
            }
        }).catch(err => {
            console.error(err.response)
        }).finally(() => setLoading(false))
    }

    useEffect(() => {
        let id = props.match.params.id
        if (templateLatestCount > 0)
            getWhatsappTemplateList(id)
    }, [templateLatestCount])

    const reArrangeOutcomeTemplate = (whatsapp) => {
        if (whatsapp) {
            const tempArray = []
            for (const property in whatsapp) {
                tempArray.push({
                    outcome: property,
                    templateId: whatsapp[property]
                })
            }
            setOutcomeTemplate(tempArray)
        }
    }

    const fetchTemplateTitle = (id) => {
        const template = templates.find(obj => {
            if (obj.id == id)
                return obj.title
        })
        return template && template.title ? TruncateString(template.title, 15) : 'Choose Template'
    }


    const outcomeDropdownListClass = (option, selOption) => {
        const filterSelected = outcomeTemplate.map(item => {
            if (item.outcome)
                return item.outcome
        }).filter(Boolean)

        if (option == selOption)
            return `option-selected`

        if (filterSelected && filterSelected.length > 0) {
            const outcomeAlreadySelected = filterSelected.includes(option)
            return outcomeAlreadySelected ? `disable-option` : ''
        }
        else
            return ``
    }

    const templateDropdownListClass = (option, selOption) => {
        if (option == selOption)
            return `option-selected`
        else
            return ``
    }

    const addOutcomeClass = () => {
        const noOfOutcomes = formData.outcomes.length
        const noOfOutComeTemp = outcomeTemplate.length
        if (noOfOutComeTemp >= noOfOutcomes)
            return `add-cta flex-end disappear`
        else
            return `add-cta flex-end`
    }


    const addTemplate = () => {
        let newTemplateCount = [...templates, dummyTemplate]
        setTemplates(newTemplateCount)
        setCheckIfLatest(true)
    }
    const addOutcomeTemplate = () => {
        let outcomeTemplateCount = [...outcomeTemplate, dummyoutcomeTemplateLink]
        setOutcomeTemplate(outcomeTemplateCount)
    }

    const removeTemplate = (index) => {
        let copyTemplates = [...templates]
        const indexToRemoved = templates.findIndex((i, idx) => idx == index)
        copyTemplates.splice(indexToRemoved, 1)
        setTemplates(copyTemplates)
        setCheckIfLatest(true)
    }

    const removeKeyValue = (index, data, i) => {
        let copyTemplates = [...templates]
        data.variables.splice(i, 1)
        const jsonObject = data.variables.reduce((acc, obj) => {
            acc[obj.name] = obj.value;
            return acc;
          }, {});
        let newobj = {}
        let obj = copyTemplates[index]
        newobj = {
            title: obj.title,
            teleproject: obj.teleproject,
            active: obj.active,
            isEdited: true,
            ...jsonObject
        }
        if(obj.id) newobj.id = obj.id
        copyTemplates[index] = newobj
        setTemplates(copyTemplates)
        setCheckIfLatest(false)
    }


    const getFormData = (key, value, idx, data, i, type) => {
        setCheckIfLatest(false)
        const tmpArray = [...templates]
        if(key == 'title'){
            let obj = tmpArray[idx]
            obj.title = value
            obj.isEdited = true
            tmpArray[idx] = obj
            setTemplates(tmpArray)
            return true
        }
        if(key == 'wa_from'){
            let obj = tmpArray[idx]
            obj.wa_from = value
            obj.isEdited = true
            tmpArray[idx] = obj
            setTemplates(tmpArray)
            return true
        }
        if(key == 'wa_template_id'){
            let obj = tmpArray[idx]
            obj.wa_template_id = value
            obj.isEdited = true
            tmpArray[idx] = obj
            setTemplates(tmpArray)
            return true
        }
        if(key == 'active'){
            let obj = tmpArray[idx]
            obj.active = value
            obj.isEdited = true
            tmpArray[idx] = obj
            setTemplates(tmpArray)
            return true
        }
        if(type == 'name'){
            data.variables[i] = {name: value, value: data.variables[i].value}
        }
        if(type == 'value') {
            data.variables[i] = {name: key, value: value}
        }
        const jsonObject = data.variables.reduce((acc, obj) => {
            acc[obj.name] = obj.value;
            return acc;
          }, {});
        let newobj = {}
        let obj = tmpArray[idx]
        newobj = {
            title: obj.title,
            teleproject: obj.teleproject,
            active: obj.active,
            isEdited: true,
            wa_from: obj.wa_from,
            wa_template_id: obj.wa_template_id,
            ...jsonObject
        }
        if(obj.id) newobj.id = obj.id
        tmpArray[idx] = newobj
        setTemplates(tmpArray)
    }

    const checkValidation = (templateBody, idx) => {
        let errorMsg = ''

        function containsOnlyDigits(str) {
            return /^\d+$/.test(str);
        }

        if(templateBody.wa_from && templateBody.wa_from.length > 10){
            errorMsg = 'Please enter a valid 10 digit mobile number'
        }

        if(templateBody.wa_from && !containsOnlyDigits(templateBody.wa_from)){
            errorMsg = 'Only numeric values are accepted for the mobile number'
        }

        if(errorMsg){
            const copyTemplates = templates.map((item, index) => {
                if (index == idx) {
                    return {
                        ...item,
                        isSaving: false,
                        errorMsg: errorMsg
                    }
                } else
                    return item
            })
            setTemplates([...copyTemplates])
            return true
        }
        return false
    }

    const saveTemplate = (idx) => {
        setCheckIfLatest(false)
        let extractTemplate = templates[idx]
        extractTemplate.isSaving = true;
        const { isEdited, isSaving, tag, errorMsg, ...rest} = extractTemplate
        const templateBody = rest;
        //if id present template is created else it needs to be created
        const isNewTemplate = templateBody.id ? false : true
        // const isNewTemplate = true

        const resultCheck = checkValidation(templateBody, idx)
    
        if(resultCheck){
            return
        }

   
        if (isNewTemplate) {
            ApiActions.createWhatsappTemplate(templateBody).then(resp => {
                setTemplateLatestCount(templateLatestCount + 1)
                toast("Whatsapp template created");
            }).catch(err => {
                console.error(err.response)
                const {data} = err.response
                let msg = ''
                if (data !== null && typeof data === 'object' && !Array.isArray(data)) {
                    msg = data.error
                }else if(Array.isArray(data)){
                    const [first] = data
                    msg = `${first.message} : ${first.params.additionalProperty}`
                }else{
                    msg = 'Error'
                }
                const copyTemplates = templates.map((item, index) => {
                    if (index == idx) {
                        return {
                            ...item,
                            isSaving: false,
                            errorMsg: msg
                        }
                    } else
                        return item
                })
                setTemplates([...copyTemplates])
            })
        } else {
            ApiActions.updateWhatsappTemplate(templateBody.id, templateBody).then(resp => {
                setTemplateLatestCount(templateLatestCount + 1)
                toast("Whatsapp template updated");
            }).catch(err => {
                console.error(err.response)
                const {data} = err.response
                let msg = ''
                if (data !== null && typeof data === 'object' && !Array.isArray(data)) {
                    msg = data.error
                }else if(Array.isArray(data)){
                    const [first] = data
                    msg = `${first.message} : ${first.params.additionalProperty}`
                }else{
                    msg = 'Error'
                }
                const copyTemplates = templates.map((item, index) => {
                    if (index == idx) {
                        return {
                            ...item,
                            isSaving: false,
                            errorMsg: msg
                        }
                    } else
                        return item
                })
                setTemplates([...copyTemplates])
            })
        }
    }

    const selectOutcome = (value, idx) => {
        const tmpArray = [...outcomeTemplate]
        let obj = outcomeTemplate[idx]
        obj.outcome = value.title
        tmpArray[idx] = obj
        setOutcomeTemplate(tmpArray)
    }

    const selectTemplate = (value, idx) => {
        const tmpArray = [...outcomeTemplate]
        let obj = outcomeTemplate[idx]
        obj.templateId = value.id
        tmpArray[idx] = obj
        setOutcomeTemplate(tmpArray)
    }

    const removeOutcomeTempLink = (index) => {
        let copyOutcomeTemplates = [...outcomeTemplate]
        const indexToRemoved = outcomeTemplate.findIndex((i, idx) => idx == index)
        copyOutcomeTemplates.splice(indexToRemoved, 1)
        setOutcomeTemplate(copyOutcomeTemplates)
    }

    const addOutcomeToTemplate = (json) => {
        const data = {
            teleproject: props.match.params.id,
            templateData: json
        }

        ApiActions.linkOutcomeToWhatsappTemplate(data).then(resp => {
            if (resp.data.whatsapp) {
                let copyFormData = { ...formData }
                copyFormData.whatsapp = resp.data.whatsapp
                setFormData(copyFormData)
                toast("Outcome linked to Whatsapp template");
                reArrangeOutcomeTemplate(resp.data.whatsapp)
                // getWhatsappTemplateList(props.match.params.id)
            }
        }).catch(err => {
            console.error(err.response)
        })
    }

    const outcomeTemplVal = () => {
        const inValid = outcomeTemplate.some(obj => {
            if (!obj.outcome || !obj.templateId)
                return true
            else
                return false
        })
        return inValid
    }

    if(!loading){
        return (
            <div>
                   <div className='templates_wrapper'>
                    <div className='template_type_select_row'>
                     <a className='doc-link' href='https://docs.google.com/document/d/1QNiT8EF13rvuzYcBmJE24kqi1HsQubGDKUNjsJkMnFQ/edit' target="_blank">Whatsapp Documentation</a>
                    </div>
                        <div>
                            {/* <div className='notify'>Note: The default template available is a static dynamic template with the variables wa_from & wa_template_id. If you want to create the dynamic template then please refer the documentation link above to add more variables</div> */}

                            {
                                templates.map((item, idx) => <WhatsappTemplateForm removeTemplate={removeTemplate}
                                    removeKeyValue={removeKeyValue}
                                    getFormData={getFormData} idx={idx} item={item}
                                    saveTemplate={saveTemplate}
                                    templatesCount={templates && templates.length > 0 ? templates.length - 1 : 0}
                                    setCheckIfLatest={setCheckIfLatest}
                                    addTemplate={addTemplate}
                                    // addVariable={addVariable}
                                    // showSMSTestingModal={showSMSTestingModal}
                                    outcomeTemplate={outcomeTemplate}
                                    addOutcomeToTemplate={addOutcomeToTemplate}
                                    projectData={formData} />)
                            }
    
                         
                            {checkIfLatest ? <AlwaysScrollToBottom /> : null}
                        </div>
                        
                    </div>
                        <div className="btn-wrapper">
                        <FrappButton type="submit" className="submit"
                            handler={() => props.history.push({
                                pathname: `/projects`,
                            })}
                        >Finish</FrappButton>
                        <FrappButton className="submit" id="new-btn-2"
                            handler={() => props.history.push({
                                pathname: `/projects`,
                            })}
                        > Skip</FrappButton>
                    </div>

                    <ToastContainer draggable={true} />
            </div>
        )
    } else
    return (
        <div className="loader-main" id="loader-size">
            <img src={require("../../assets/images/loadernew.gif")} />
            <div className="text">
                We'll be right back. Here: listen to some awesome songs meanwhile
        <a href="https://open.spotify.com/playlist/37i9dQZF1DX3rxVfibe1L0" target="_blank" rel="noopener noreferrer"> Take me</a>
            </div>
        </div>
    )
}

export default WhatsappTemplate