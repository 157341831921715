import { withRouter } from 'react-router-dom'
import React, { Component } from 'react'
import CreateQuiz from "../../pages/CreateQuiz";
import ApiActions from '../../../actions/ApiActions';
import FrappModal from "../../utilities/FrappModal";
import FrappButton from '../../utilities/FrappButton';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import FormControl from 'react-bootstrap/lib/FormControl';
import MaterialTable, { MTablePagination } from "material-table";
import Dropdown from 'react-dropdown';
import { ToastContainer, toast } from "react-toastify";
import ProjectTitle from '../../common/ProjectTitle';

class QuizFinal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mission: null,
            missionId: "",
            missionData: [],
            missionModal: {},
            formData: {},
            questions: [],
              skip:0,
            quiz:{},
        }
    }


    componentDidMount() {
          let {skip}=this.state;
         let query={
            sort: { createdAt: -1 }
        }
        ApiActions.getQuizList(query,skip).then(resp=>{
             skip=skip+50;
            this.setState({quizList:resp,quiz:{},skip})
         })
    }
     fetchData=()=>{
       let {skip,quizList}=this.state;
       let newData;
       let query={
            sort: { createdAt: -1 }
        }
       ApiActions.getQuizList(query,skip).then(resp=>{
        skip=skip+50;
        newData=quizList.concat(resp)
        this.setState({skip,quizList:newData})       
    
    })
   }
     componentWillUnmount() {
        this._isMounted = false;
         

    }
    render() {
        var { quizList,quiz } = this.state;
        return (
            <div className='tools'>
                <div className='container'>
                <ProjectTitle title={`Quiz `} goBack={() => this.props.history.push("/tools")} />
                <div className="quiz-wrapper">
                      <FrappButton className="submit" id="create-new" ref={(c) => this.createNewBtn = c} handler={() => this.createNewQuiz()}  > Create </FrappButton>
                    {this.renderQuizTable()}
                    {this.state.createQuiz?this.renderForm():null}
                    {this.quizNameModal()}
                        <ToastContainer draggable={true} />
                </div>
                </div>
            </div>
        )
    }
    onMissionSelect = (mission) => {
        this.setState({ mission })

    }
    renderForm = () => {
       return (<CreateQuiz />)
    }


quizNameModal=()=>{
        let quiz = this.state.quiz;
        return (
            <FrappModal
                onCloseButton={true}
                className="quiz-modal"
               // show={this.state.showQuiz}
                ref={c => (this.namemodal = c)}
                closeModal={() => this.namemodal.toggle(false)}
            >
               {this.updateQuizName()}
            </FrappModal>
        )
   
}
updateQuizName=()=>{
    let quiz=this.state.quiz;
      let options=[
           { label: "Mission",value:"mission"},
           { label: "Teleproject",value:"teleproject"},
           { label: "Training",value:"training"}
        ]
    return( 
               <form onSubmit={(e)=>this.changeQuizName(e)}>
                    <div>
                         <FormGroup>
                                <ControlLabel>Quiz Name</ControlLabel>
                                <FormControl value={quiz.name || ""} onChange={this.setQuizName} name="name" required type="text" placeholder="Enter the name of Quiz" />
                            </FormGroup>
                             <FormGroup>
                                <ControlLabel>Category</ControlLabel>
                                <Dropdown options={options} 
                                  onChange={(val)=>this.setQuizCategory(val)} 
                                   value={quiz.category} 
                                   placeholder="Select an option" />
                            </FormGroup>
                             <FrappButton type="submit" className="submit" ref={(c) => this.submitBtnQuizName = c} >Submit</FrappButton>
                    </div>
               </form>

                    )
}
 setQuizName=(e)=>{
         let quiz = this.state.quiz;
         quiz.name=e.target.value;
         this.setState({quiz})
    }
 setQuizCategory=(val)=>{
        let { quiz } = this.state;
       quiz.category=val.value;

        this.setState({ quiz })
    }
changeQuizName=(e)=>{
    e.preventDefault();
    this.submitBtnQuizName.startLoading();
    let quiz=this.state.quiz;
    let dataToSend={
        "name":quiz.name,
        "category":quiz.category
    }
    ApiActions.changeQuizName(quiz.id,dataToSend).then(resp=>{
        this.submitBtnQuizName.stopLoading();
       toast("Quiz updated")
       this.namemodal.toggle(false)
    })
    }
   renderQuizTable=()=>{
        let {quizList}=this.state;
        
        return (
            <div style={{ maxWidth: "100%" }}>
                <MaterialTable
                    localization={{
                        pagination: {
                            labelDisplayedRows: '{from}-{to} of {count}',
                        },
                        toolbar: {
                            nRowsSelected: '{0} row(s) selected'
                        },
                        header: {
                            actions: 'Actions'
                        },
                        body: {
                            emptyDataSourceMessage: 'No records to display',
                            filterRow: {
                                filterTooltip: 'Filter'
                            }
                        }
                    }}
                    columns={[
                        {
                        field: "name", cellStyle: {
                                fontSize: '12px',
                                color: 'black'
                            }, headerStyle: {
                                backgroundColor: '#E7FAF2',
                                fontSize: '12px',
                                fontWeight: 'bold',
                                boxShadow: 'none'

                            },
                            filterCellStyle: {
                                padding: '1% 0% 1% 1%'
                            },


                        },
                        {
                        field: "category", cellStyle: {
                                fontSize: '12px',
                                color: 'black'
                            }, headerStyle: {
                                backgroundColor: '#E7FAF2',
                                fontSize: '12px',
                                fontWeight: 'bold',
                                boxShadow: 'none'

                            },
                            filterCellStyle: {
                                padding: '1% 0% 1% 1%'
                            },


                        },
                        {
                            field: "id", cellStyle: {
                                fontSize: '12px',
                                color: 'black'
                            }, headerStyle: {
                                backgroundColor: '#E7FAF2',
                                fontSize: '12px',
                                fontWeight: 'bold',
                                boxShadow: 'none'

                            },
                            filtering:false

                        },
                        {
                            title: "",
                           render: rowData => 
                        <div >
                           <FrappButton className="edit-quiz-btn"
                           handler={()=>this.createNewQuiz(rowData)}>
                              Edit Quiz Questions
                           </FrappButton>
                         {/* <img src={require("../../assets/images/green-tick.svg")}/> */}
                        </div>,
                            cellStyle: {
                                fontSize: '12px',
                                color: 'black'
                            }, headerStyle: {
                                backgroundColor: '#E7FAF2',
                                fontSize: '12px',
                                fontWeight: 'bold'
                            },
                            filtering: false
                        },

                          {
                            title: "",
                           render: rowData => 
                        <div >
                           <FrappButton className="edit-quiz-btn"
                           handler={()=>this.handleClickName(rowData)}>
                              Edit Quiz Name or Category
                           </FrappButton>
                         {/* <img src={require("../../assets/images/green-tick.svg")}/> */}
                        </div>,
                            cellStyle: {
                                fontSize: '12px',
                                color: 'black'
                            }, headerStyle: {
                                backgroundColor: '#E7FAF2',
                                fontSize: '12px',
                                fontWeight: 'bold'
                            },
                            filtering: false
                        },
                       

                       
                    ]}

                    data={quizList}
                    // components={{
                    //     Pagination: props => (
                    //         <div style={{ fontSize: "20px" }}>
                    //             <MTablePagination rowsPerPage={50} {...props} />
                    //         </div>
                    //     )
                    // }}
                    components={{
                        Pagination: props => (
                            <div style={{ fontSize: "30px" }}>
                                <MTablePagination rowsPerPage={50} {...props} />
                            </div>
                        )
                    }}
                     onChangePage={()=>this.fetchData()}
                    options={{
                        filtering:true,
                        doubleHorizontalScroll:true,
                        pageSize:25,
                        emptyRowsWhenPaging:false,
                      //  pageSizeOptions:[25,50,75,100],
                     //   paginationType:"stepped",
                        rowStyle: {
                            fontSize: "16px"
                        },
                        searchFieldStyle: {
                            boxShadow: 'none',
                            webkitboxShadow: 'none',
                        },
                        header:false

                    }}
                    title="Tele-Callers"
                />



            </div>
        )
    }
    createNewQuiz = (quiz) => {
        this.setState({createQuiz:true})
        if(quiz&&quiz.id){
              this.props.history.push({
                pathname: `/update-quiz/${quiz.id}`,
                state: {
                    formData: quiz,
                    createQuiz: false
                }
            })
        }
         else {
            this.props.history.push({
                pathname: `/create-quiz`,
                state: {
                    createQuiz: true
                }
                })
           
        }
        // if(quiz&&quiz.id)
        //     ApiActions.getQuiz(quiz.id).then(resp => {
        //         this.setState({ questions: [...resp.questions],quiz:quiz}, this.modal.toggle(true))

        //     })
        // else 
        // this.setState({ quiz:{}}, this.modal.toggle(true))
    }



      handleClickName = (quiz) => {
          if(quiz&&quiz.id)
          this.setState({ quiz:quiz}, this.namemodal.toggle(true))
    }

}
export default withRouter(QuizFinal) 