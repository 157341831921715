import React from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";
import Payments from '../pages/caller/payment'
import Roster from '../pages/caller/roaster'
import AttendanceTracker from '../pages/caller/attendance-tracker'
import Payout from '../pages/caller/attendance-tracker/payout';
import ConfirmPayout from '../pages/caller/attendance-tracker/confirmPayout'

const CallerRouter = (props) => {
    const { path } = props.match
    const customProps = {
        ...props,
        modulePath: path
    }

    return <Switch>
        <Route exact path={`${path}/payment`}
        render={() => <Payments {...customProps} />}
        />

        <Route exact path={`${path}/attendance-tracker`}
        render={() => <AttendanceTracker {...customProps} />}
        />

        <Route exact path={`${path}/payout`}
        render={() => <Payout {...customProps} />}
        />

        <Route exact path={`${path}/payout/confirm`}
        render={() => <ConfirmPayout {...customProps} />}
        />

        <Route exact path={`${path}`}
        render={() => <Roster {...customProps} />}
        />
        {/* if there is no route match then redirect to the root path */}
        <Route render={() => <Redirect to="/" />} />
    </Switch>
}

export default CallerRouter