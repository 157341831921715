import React, { useState, useContext, Fragment, useEffect, useLayoutEffect, useCallback, useRef } from 'react'
import ReactFlow, { isNode, ReactFlowProvider, removeElements, addEdge, updateEdge, Background, Controls, MiniMap, Handle, getBezierPath, getSmoothStepPath, getMarkerEnd, EdgeText, getEdgeCenter } from 'react-flow-renderer';
import { ToastContainer, toast } from "react-toastify";
import cloneDeep from "lodash/cloneDeep";
import shortid from 'shortid';
import { CSVLink, CSVDownload } from "react-csv";
import Editor from './Editor'
import Api from "../../../util/Api";
import FrappModal from '../../utilities/FrappModal'
import FrappButton from '../../utilities/FrappButton'
import FormControl from 'react-bootstrap/lib/FormControl';
import Loader from '../../utilities/Loader';
import CustomTooltip from '../../utilities/ToolTip'
import PreviewInteractiveScript from '../Tools/PreviewInteractiveScript'
import ApiActions from '../../../actions/ApiActions';
import Papa from 'papaparse';
import { ISContext } from './ISContext'
import FormGroup from "react-bootstrap/lib/FormGroup";
import DataCollectionForm from './DataCollectionForm'
import TriggerActionForm from './TriggerActionForm'
import Popup from '../../../uicomponents/popup'
import LabelCreation from './LabelCreation'
import FutworkButton from '../../../uicomponents/button/Button';
import Faq from './Faq';
import Tab from '../../../uicomponents/tab'
import withTabGroup from '../../../hocs/withTabGroup'

const GraphDiagram = (props) => {
    const { outcomes, dcOutcomes, setDCOutcomes, isLoading, practicePublished, teleproject, dataForFaq, setFaqs, setFaqApi, isFaq, scriptDocLink, msgStatus, setMsgStatus, scriptId, publishTheScript, showPublishModal, setShowPublishModal, isLinkCopied, setIsLinkCopied, showLinkShareModal, setShowLinkShareModal, scriptPreview, setScriptPreview, progressPercent, setProgressPercent, isSubmitTriggered, setSubmitTriggered, isTextToSpeechDone, setTextToSpeechDone, reactFlowInstance, activeedge, setEdge, setEdgeForm, showEdgeForm, crud, showCrud, heading, setHeading, formDataToSend, setFormDataToSend, removeElement, setRemovElement, currentNodeData, setCurrentNodeData, currentEdgeData, setCurrentEdgeData, setDirty, setUnSaved, setReactFlowInstance, activenode, nodeDragged, setNodeDragged, setNode, isDataPresent, teleprojectTitle, unSaved, scriptPublished, teleprojectdata, faqs, outcomeFlag, setOutcomeTrue, allScripts, setAllScripts2, allScripts2, elements, setElements, elements1, setElements1, taskProps, setDataCollectionPractice,
    activeLang, setActiveLang, faqActiveLanguage, setActiveFaqLanguage } = useContext(ISContext)
    const [apiMsg, setApiMsg] = useState('')
    const reactFlowWrapper = useRef(null);
    const [publishOption, setPublishOption] = useState([
        {
            label: 'Script',
            name: 'script',
            checked: false
        },
        // the checkbox for IS practice is being deprecated from June 2024
        // {
        //     label: 'Interactive practice (Optional)',
        //     name: 'practice',
        //     checked: false
        // }
    ])

    const [showDCModal, setDCModal] = useState(false)
    const [showActionTrigger, setShowActionTrigger] = useState(false)
    const [showAddLabel, setShowAddLabel] = useState(false)

    const [formData, setFormData] = useState('')
    const [showISPreview, setShowISPreview] = useState(false)
    const [showDeleteScriptPopup, setShowDeleteScriptPopup] = useState(false)
    const [showDeleteButton, setShowDeleteButton] = useState(false)
    const [showFAQPopup, setShowFAQPopup] = useState(false)
    const foreignObjectSize = 20;
    const standardColors = ['peach', 'olive_green', 'purple', 'pink', 'aqua_blue', 'sky_blue', 'dark_pink', 'orange', 'aqua_green']
    let color;

    const mutationRef = useRef(elements);
    const mutationRefAllScripts2 = useRef(allScripts2)
    const mutationRefAllScripts = useRef(allScripts)

    const [deleteScriptMsg, setDeleteScriptMsg] = useState({
        type: '',
        msg: ''
    })

    let checkIfScriptHeadings = allScripts[0]  && allScripts[0].scriptHeadings && allScripts[0].scriptHeadings;

    if(checkIfScriptHeadings){
        [...outcomes, ...checkIfScriptHeadings].forEach((el, i) => {
            color = standardColors[i % standardColors.length];
        });
    }else{
        [...outcomes].forEach((el, i) => {
        color = standardColors[i % standardColors.length];
        });
    }
    
    let combinedOutcomes;
    if(checkIfScriptHeadings){
        combinedOutcomes = [...outcomes, ...checkIfScriptHeadings]
    }else{
        combinedOutcomes = [...outcomes]
    }
    const colorCodeOutcomes = combinedOutcomes.map((e, i) => {
        return {
            'title': e.toLowerCase().includes('flow') ? e : outcomes.includes(e) ? (`${e} Flow`) : e,
            'color': color = standardColors[i % standardColors.length]
        }
    })

    //used to display the form on click of edge
    const showForm = (id, label, source, target, outcome, data, style, animated) => {
        refreshState()
        setEdgeForm(true)
        showCrud(false)
        elements[0].selected = true
        if (outcome) {
            setEdge({
                "id": id,
                "source": source,
                "target": target,
                "label": label,
                "data": {
                    "outcome": outcome
                },
                "style": style,
                "type": "step",
                "animated": true
            })
            combineLanguageAndSelectededgeData({
                "id": id,
                "source": source,
                "target": target,
                "label": label,
                "data": {
                    "outcome": outcome
                },
                "style": style,
                "type": "step",
                "animated": true
            })
        } else {
            setEdge({
                "id": id,
                "source": source,
                "target": target,
                "label": label,
                // "data": data,
                "style": style,
                "type": "step",
                "animated": false
            })
            combineLanguageAndSelectededgeData({
                "id": id,
                "source": source,
                "target": target,
                "label": label,
                // "data": data,
                "style": style,
                "type": "step",
                "animated": false
            })
        }
    }

    const refreshState = () => {
        setTextToSpeechDone(false)
        setSubmitTriggered(false)
        setProgressPercent('')
    }

    //this fn gets called when the 'Introduction node is clicked'
    const CustomNodeComponentInput = (data) => {
        // get the node id after selection  
        // using this id fetch all the node of respective languages and save them in an array
        //set the array in activenode
        const setData = (node) => {
            setNode(node)
            combineLanguageAndSelectednodeData(node)
            setHeading(node.data.heading || "Start")
            refreshState()
            showCrud(!crud)
        }
        // let found = elements.find(e => e.id == data.id)
        let found = mutationRef.current.find(e => e.id == data.id)
        return (
            <div className={getNodeColor(data)} onClick={() => setData(data)}>
                <Handle type="source" position="bottom" style={{ borderRadius: 0, height: 10, width: 10 }} onConnect={onConnect} />
                <div className={checkHeadingNodeClass(data)}>
                    <span>{data.data.heading}</span>
                    {found && found.initialNode ? <span style={{fontStyle: 'italic'}}>(Primary node)</span>: null}
                    {found && found.initialNode ? <img src={require('../../../assets/images/Icons_dark_star.svg')}/> : null}
                    {data.data.audio ? <img src={require('../../../assets/images/Interactive Script/Icons_audio_bold.svg')} /> : null}
                </div>
                <div className='text'>{data.data.label}</div>
                    <div className="node-attachment-wrapper">
                    {data.data.actions && data.data.actions.length > 0 ? <div className="trigger-action"><img src={require('../../../assets/images/Icons_IS_trigger_white.svg')}/>Trigger Action</div> : null}
                    {data.data.form && data.data.form.length>0 ? <div className="data-collection-box">Form</div> : null}
                    {data.data.callerCueText ? <div className="caller-cue-box">
                    {data.data.callerCueText}
                    </div> : null}
                </div>
                {/* <div>{data.data.label}</div>
                <div className={data.data.callerCueText ? `data-collection-with-caller-cue` : `data-collection`}>{data.data.form ? "Form" : null}</div>
                <div className={data.data.form ? `caller-cue` : `caller-cue-only`}>{data.data.callerCueText}</div> */}
            </div>
        );
    };

    const CustomNodeComponentOutput = (data) => {
        const setData = (node) => {
            setNode(node)
            combineLanguageAndSelectednodeData(node)
            setHeading(node.data.heading || "Ending")
            refreshState()
            showCrud(!crud)
        }
        let found = mutationRef.current.find(e => e.id == data.id)
        return (
            <div className={getNodeColor(data)} onClick={() => setData(data)}>
                <Handle type="target" position="top" style={{ borderRadius: 0, height: 10, width: 10 }} onConnect={onConnect} />
                {/* <ToastContainer draggable={true} /> */}
                <div className={checkHeadingNodeClass(data)}>
                    <span>{data.data.heading}</span>
                    {found && found.initialNode ? <span style={{fontStyle: 'italic'}}>(Primary node)</span>: null}
                        {found && found.initialNode ? <img src={require('../../../assets/images/Icons_dark_star.svg')}/> : null}
                    {data.data.audio ? <img src={require('../../../assets/images/Interactive Script/Icons_audio_bold.svg')} /> : null}
                </div>
                <div className='text'>{data.data.label}</div>
                    <div className="node-attachment-wrapper">
                    {data.data.actions && data.data.actions.length > 0 ? <div className="trigger-action"><img src={require('../../../assets/images/Icons_IS_trigger_white.svg')}/>Trigger Action</div> : null}
                    {data.data.form && data.data.form.length>0 ? <div className="data-collection-box">Form</div> : null}
                    {data.data.callerCueText ? <div className="caller-cue-box">
                    {data.data.callerCueText}
                    </div> : null}
                </div>
                {/* <div >{data.data.label}</div>
                <div className={data.data.callerCueText ? `data-collection-with-caller-cue` : `data-collection`}>{data.data.form ? "Form" : null}</div>
                <div className={data.data.form ? `caller-cue` : `caller-cue-only`}>{data.data.callerCueText}</div> */}
            </div>
        );
    };



    const getEdgeBorderColor = (data, style, target, source, id) => {
        const findTheTargetNode = [...elements].filter(e => e.id == target)[0]
        let node = findTheTargetNode && colorCodeOutcomes.find(e => e.title == findTheTargetNode.data.heading)
        const node_color = node && node.color

        switch (node_color) {
            case 'peach':
                return `#BE5000`
                break;
            case 'olive_green':
                return `#809F00`
                break;
            case 'purple':
                return `#415ED8`
                break;
            case 'pink':
                return `#CE16BE`
                break;
            case 'aqua_blue':
                return `#1CADAD`
                break;
            case 'sky_blue':
                return `#023349`
                break;
            case 'dark_pink':
                return `#7B0ABE`
                break;
            case 'orange':
                return `#EE9310`
                break;
            case 'aqua_green':
                return `#1BAF7E`
                break
            default:
                return `black`
                break;
        }
    }

    const getNodeColor = (nodeInfo) => {
        const { heading } = nodeInfo.data
        let node_heading = colorCodeOutcomes.find(e => e.title == heading)
        if (node_heading) {
            return `${node_heading.color}_node`
        }

        switch (heading) {
            case 'Quick Pitch':
                return `quick_pitch_node`
                break
            case 'Closing':
                return `closing_node`
                break
            case 'Main Pitch':
                return `main_pitch_node`
                break
            case 'Introduction':
                return `introduction_node`
                break
            case 'Start':
                return `start_ending_node`
                break
            case 'Ending':
                return `start_ending_node`
                break
            default:
                return `aqua_blue_node`
                break;
        }
        
    }

    const checkHeadingNodeClass = (nodeInfo) => {
        const { heading, actions } = nodeInfo.data
        let node_heading = colorCodeOutcomes.find(e => e.title == heading)
        if (node_heading) {
            return `heading-node-i ${node_heading.color}`
        }
        switch (heading) {
            case 'Closing':
                return `heading-node-i closing`
                break
            case 'Quick Pitch':
                return `heading-node-i quick_pitch`
                break
            case 'Introduction':
                return `heading-node-i introduction`
                break
            case 'Main Pitch':
                return `heading-node-i main_pitch`
                break
            case 'Start':
                return `heading-node-i start_end`
                break
            case 'Ending':
                return `heading-node-i start_end`
                break
            default:
                return `heading-node-i aqua_blue`
                break;
        }
    }

    const CustomNodeComponentDefault = (data) => {
        const setData = (node) => {
            setNode(node)
            combineLanguageAndSelectednodeData(node)
            setHeading(node.data.heading || "Main Pitch")
            refreshState()
            showCrud(!crud)
        }
        let found = mutationRef.current.find(e => e.id == data.id)
        return (
            <div className={getNodeColor(data)}>
                <div onClick={() => setData(data)}>
                    <Handle type="source" position="bottom" style={{ borderRadius: 0, height: 10, width: 10 }} onConnect={onConnect} />
                    <Handle type="target" position="top" style={{ borderRadius: 0, height: 10, width: 10 }} onConnect={onConnect} />
                    {/* <ToastContainer draggable={true} /> */}
                    <div className={checkHeadingNodeClass(data)}>
                        <span>{data.data.heading}</span>    
                        {found && found.initialNode ? <span style={{fontStyle: 'italic'}}>(Primary node)</span>: null}
                        {found && found.initialNode ? <img src={require('../../../assets/images/Icons_dark_star.svg')}/> : null}
                        {/* {found && found.data && found.data.actions && found.data.actions.length > 0 ? <img src={require('../../../assets/images/Icons_IS_trigger_black.svg')}/> : null}  */}
                        {data.data.audio ? <img src={require('../../../assets/images/Interactive Script/Icons_audio_bold.svg')} /> : null}
                        {/* {data.initialNode ? <img src={require('../../../assets/images/Interactive Script/Icons_audio_bold.svg')} /> : null} */}
                    </div>
                    <div className='text'>{data.data.label}</div>
                    <div className="node-attachment-wrapper">
                    {data.data.actions && data.data.actions.length > 0 ? <div className="trigger-action"><img src={require('../../../assets/images/Icons_IS_trigger_white.svg')}/>Trigger Action</div> : null}
                    {data.data.form && data.data.form.length>0 ? <div className="data-collection-box">Form</div> : null}
                    {data.data.callerCueText ? <div className="caller-cue-box">
                    {data.data.callerCueText}
                    </div> : null}
                    </div>
                   
                    {/* data.actions && data.actions.length > 0 */}
                    {/* <div className={data.data.callerCueText ? `data-collection-with-caller-cue` : `data-collection`}>{data.data.form ? "Form" : null}</div>
                    <div className={data.data.form ? `caller-cue` : `caller-cue-only`}>{data.data.callerCueText}</div> */}
                </div>
            </div>
        );
    };

    const CustomEdge = ({
        id,
        sourceX,
        sourceY,
        targetX,
        targetY,
        sourcePosition,
        targetPosition,
        style,
        outcome,
        data,
        source,
        target,
        arrowHeadType = 'arrowclosed',
        label,
        animated,
        markerEndId,
    }) => {
        const edgePath = getSmoothStepPath({
            sourceX,
            sourceY,
            sourcePosition,
            targetX,
            targetY,
            targetPosition,
            borderRadius: 15
        });

        // const [edgeCenterX, edgeCenterY] = getEdgeCenter({
        //     sourceX,
        //     sourceY,
        //     targetX,
        //     targetY,
        //   });

        const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);
        const [centerX, centerY] = getEdgeCenter({
            sourceX,
            sourceY,
            targetX,
            targetY,
        });

        return (
            <>
                <path id={id} style={{...style, strokeWidth: '3.5px'}} className={getColorForEdge(data, style, target, source, id)} d={edgePath} markerEnd={markerEnd}
                    onClick={() => showForm(id, label, source, target, data && data.outcome, data, style, animated)} />
                {/* <text>
                    <textPath href={`#${id}`}  labelStyle={{fontSize: '12px', fill: 'red'}} startOffset="50%" textAnchor="middle">
                        {data && data.outcome || label}
                    </textPath>
                </text> */}
                <EdgeText
                    x={centerX}
                    y={centerY}
                    label={(
                        <>
                            <tspan>{data && data.outcome || label}</tspan>
                        </>
                    )}
                    labelStyle={{ fontSize: '12px', fill: getEdgeBorderColor(data, style, target, source, id) }}
                // labelStyle={{ fill: 'white' }}
                // labelShowBg
                // labelBgStyle={{ fill: 'red' }}
                // labelBgPadding={[2, 4]}
                // labelBgBorderRadius={2}
                />
                <foreignObject
                    width={foreignObjectSize}
                    height={foreignObjectSize}
                    x={centerX - foreignObjectSize / 2}
                    y={centerY - foreignObjectSize / 16}
                    className="edgebutton-foreignobject"
                    requiredExtensions="http://www.w3.org/1999/xhtml"
                >
                    {data && data.audio ? <img src={require('../../../assets/images/Interactive Script/Icons_audio_bold.svg')} style={{ width: '12px', height: '12px' }} /> : null}
                </foreignObject>
            </>
        );
    }

    const nodeTypes = {
        input: CustomNodeComponentInput,
        output: CustomNodeComponentOutput,
        default: CustomNodeComponentDefault
    };
    const edgeTypes = {
        step: CustomEdge,
    };

    const onConnect = (params) => setElements((els) => addEdge(
        outcomeFlag ?
        {
            id: `e${params.source}-${params.target}`,
            source: params.source,
            target: params.target,
            label: "label",
            type: "step",
            animated: outcomeFlag,
            arrowHeadType: "arrowclosed",
            data: {
                outcome: "outcome",
                label: ''
            }
        } :
        {
            id: `e${params.source}-${params.target}`,
            source: params.source,
            target: params.target,
            label: "label",
            type: "step",
            animated: outcomeFlag,
            arrowHeadType: "arrowclosed",
            // data: {
            //     label: ''
            // }
        }, els
    ));

    const onEdgeUpdate = (oldEdge, newConnection) => setElements((els) => updateEdge(oldEdge, newConnection, els));

    const onDragStart = (event, nodeType) => {
        // debugger;
        event.dataTransfer.setData('application/reactflow', nodeType);
        event.dataTransfer.effectAllowed = 'move';
    };
    const onLoad = (reactFlowInstance) => {
        setReactFlowInstance(reactFlowInstance);
    };

    const LockPosition = (active_node) => {
        let objCopy = cloneDeep(active_node)
        let position = {
            x: 0,
            y: 0
        }
        position.x = active_node.position.x;
        position.y = active_node.position.y;

        objCopy["position"] = position
        const y = elements.find(e => e.id == active_node.id)
        if(y.initialNode){
            objCopy.initialNode = y.initialNode
        }
        setNode(
            objCopy
        )
        elements.splice(elements.findIndex(function (i) {
            return i.id === active_node.id;
        }), 1);
        elements.push(objCopy)
        // let index = active_node.id - 1;
        // elements[index] = objCopy
        setElements(elements)


    }

    const onNodeDragStop = (event, node) => {
        setNodeDragged(node)
        LockPosition(node)
    }

    const onNodeDrag = () => {
        setUnSaved(true);
        setDirty();
    }

    const onDragOver = (event) => {
        // debugger;
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    };

    //this function is to remove the nodes or edges from the flow chart
    const onElementsRemove = useCallback(
        (elementsToRemove) => {
            setRemovElement(elementsToRemove)
            setElements((els) => removeElements(elementsToRemove, els))
        },
        []
    );

    const addNode = (type, position) => {
        // debugger;
        if (type === "input" || type === "output") {
            setElements(e => e.concat({
                id: shortid.generate(),
                data: { label: type, heading: type },
                type: type,
                position: position
            }));
        }

        else {
            setElements(e => e.concat({
                id: shortid.generate(),
                data: { label: "Default", heading: "Main Pitch" },
                position: position
            }));
        }

    };

    const onDrop = (event) => {
        event.preventDefault();
        const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
        const type = event.dataTransfer.getData('application/reactflow');
        const position = reactFlowInstance.project({
            x: event.clientX - reactFlowBounds.left,
            y: event.clientY - reactFlowBounds.top,
        });
        addNode(type, position)
        // setElements((es) => es.concat(newNode));
    };

    //this is used to store the master copy so that right data is passed when submitting the script data
    const updateMasterArray = () => {
        // debugger
        if (nodeDragged) {
            formDataToSend.map(script => {
                script.script.map((each, idx) => {
                    if (each.id == nodeDragged.id) {
                        let position = nodeDragged.position
                        each.position = position
                        return each
                    }
                })
            })
        }
        if (teleprojectdata.languages && teleprojectdata.languages.length > 0) {
            if (allScripts.length > 0) {
                let result = allScripts.map(script => {
                    return {
                        scriptLanguage: script.scriptLanguage,
                        script: cloneDeep(script.script)
                    }
                })
                let r = teleprojectdata.languages.map((lan, i) => {
                    if (!result[i]) {
                        let obj = {
                            scriptLanguage: lan.name,
                            script: cloneDeep(elements)
                        }
                        return obj
                    }
                }).filter(Boolean)
                result = [...result, ...r]
                setFormDataToSend([...result])
            } else {
                let result = teleprojectdata.languages.map(lan => {
                    return {
                        scriptLanguage: lan.name,
                        script: cloneDeep(elements)
                    }
                })
                setFormDataToSend([...result])
            }
        }
    }

    useEffect(() => {
        const val = !Object.values(activenode).filter(e => typeof e !== 'undefined').length
        if (!val) {
            combineLanguageAndSelectednodeData(activenode)
        }
    }, [allScripts2])

    useEffect(() => updateMasterArray(), [elements, teleprojectdata, removeElement, nodeDragged])

    // Updating mutation ref
    useEffect(() => {
    mutationRef.current = elements;
    mutationRefAllScripts2.current = allScripts2
    mutationRefAllScripts.current = allScripts
    }, [elements, allScripts2, allScripts]);

    // process by the array by getting the node id from the selected node
    const combineLanguageAndSelectednodeData = (node) => {
        if(node.data.heading == 'Start' || node.data.heading == 'Introduction'){
            node = {...node, initialNode: true}
        }
        //using this logic to add the latest value of a node to the editor as the state takes a bit of time to update and show
        // allScripts.length > 0 && allScripts.map((script, i) => {
        //     if (formDataToSend[i].scriptLanguage == script.scriptLanguage) {
        //         script.script.map(elem => {
        //             if (elem.id == node.id) {
        //                 elem.data.label = formDataToSend[i].script.find(e => e.id == node.id).data.label
        //             }
        //         })
        //     }
        // })
        //check if the node is already present in the script that is (got from the backend api)
        //if already extracted the data from the node else store empty value
        let notANewNode = allScripts2.length > 0 ? allScripts2.find(e => e.id == node.id) : ''
        // if there is a script already present and the selected is also already present then perform the below operations
        if (allScripts2.length > 0 && notANewNode) {
            //selected node is matched within the script which was already created
            let matchedNode = allScripts2.find(e => e.id == node.id)
            let temp = []

            //teleprojectdata.languages consist of all the languages present for the project
            let mappedLanguageAndScript = teleprojectdata.languages.map(lang => {
                //all the scripts are mapped with their respective languages
                let result = mutationRefAllScripts.current.filter(script => script.scriptLanguage == lang.name)
                return result.length > 0 ? result[0] : result
            })
            let copyOfMappedLanguageAndScript = [...mappedLanguageAndScript]
            teleprojectdata.languages.map((lang, i) => {
                if (copyOfMappedLanguageAndScript[i].scriptLanguage == lang.name) {
                    let obj = {
                        ...lang,
                        id: copyOfMappedLanguageAndScript[i].id,
                        isEdited: true,
                        nodeData: copyOfMappedLanguageAndScript[i].script.find(elem => elem.id == matchedNode.id)
                    }
                    temp.push(obj)
                } else {
                    temp.push({ ...lang, isEdited: true, nodeData: node })
                }
            })
            //the value from temp is then passed to currentnodedata so that node data along with respective languages
            //are displayed on the text editor
            setCurrentNodeData([...temp])
            setCurrentEdgeData([])
        }
        //if it is a new node which is not already present in the script then perform this operation
        else {
            let temp = []
            teleprojectdata && teleprojectdata.languages && teleprojectdata.languages.map((lang, i) => {
                temp.push({ ...lang, isEdited: true, nodeData: node })
            })
            setCurrentNodeData([...temp])
            setCurrentEdgeData([])
        }
    }

    // process by the array by getting the edge id from the selected edge
    const combineLanguageAndSelectededgeData = (edgeData) => {
        //using this logic to add the latest value of a edge to the editor as the state takes a bit of time to update and show
        allScripts.length > 0 && allScripts.map((script, i) => {
            if (formDataToSend[i].scriptLanguage == script.scriptLanguage) {
                script.script.map(elem => {
                    if (elem.id == edgeData.id) {
                        elem.label = formDataToSend[i].script.find(e => e.id == edgeData.id).label
                        let isPresent = formDataToSend[i].script.find(e => e.id == edgeData.id)
                        if (isPresent.data && isPresent.data.label) {
                            elem.data.label = isPresent.data.label
                        }
                    }
                })
            }
        })

        let notANewEdge = allScripts2.length > 0 ? allScripts2.find(e => e.id == edgeData.id) : ''
        if (allScripts2.length > 0 && notANewEdge) {
            let matchedEdge = allScripts2.find(e => e.id == edgeData.id)
            let temp = []
            let mappedLanguageAndScript = teleprojectdata.languages.map(lang => {
                let result = mutationRefAllScripts.current.filter(script => script.scriptLanguage == lang.name)
                return result.length > 0 ? result[0] : result
            })
            let copyOfMappedLanguageAndScript = [...mappedLanguageAndScript]

            teleprojectdata.languages.map((lang, i) => {
                // allScripts.map(script => {
                if (copyOfMappedLanguageAndScript[i].scriptLanguage == lang.name) {
                    let obj = {
                        ...lang,
                        isEdited: true,
                        nodeData: copyOfMappedLanguageAndScript[i].script.find(elem => elem.id == matchedEdge.id)
                    }
                    temp.push(obj)
                } else {
                    temp.push({ ...lang, isEdited: true, nodeData: edgeData })
                }
                // })
            })
            let getSourceNode = mutationRef.current.find(elem => elem.id == temp[0].nodeData.source)
            if(getSourceNode){
            getSourceNode.data && getSourceNode.data.form && getSourceNode.data.form.map(item => {
                if(item.answerForPractice){
                    item.isPracticeAnswer = true
                }else{
                    item.isPracticeAnswer = false
                }
                return item
            })
            setDataCollectionPractice(getSourceNode)
            }
            setCurrentEdgeData([...temp])
            setCurrentNodeData([])
        } else {
            let temp = []
            teleprojectdata.languages.map((lang, i) => {
                temp.push({ ...lang, isEdited: true, nodeData: edgeData })
            })
            let getSourceNode = mutationRef.current.find(elem => elem.id == temp[0].nodeData.source)
            if(getSourceNode){
            getSourceNode.data && getSourceNode.data.form && getSourceNode.data.form.map(item => {
                if(item.answerForPractice){
                    item.isPracticeAnswer = true
                }else{
                    item.isPracticeAnswer = false
                }
                return item
            })
            setDataCollectionPractice(getSourceNode)
            }
            setCurrentEdgeData([...temp])
            setCurrentNodeData([])
        }
    }

    const removeDeletedElements = () => {
        const extractedIds = elements.map(el => el.id)
        allScripts.map(scr => {
            let p = scr.script.map(el => {
                let g = extractedIds.includes(el.id)
                if (g)
                    return el
            }).filter(Boolean)
            scr.script = p
            return scr
        })

        formDataToSend.map(scr => {
            let p = scr.script.map(el => {
                let g = extractedIds.includes(el.id)
                if (g)
                    return el
            }).filter(Boolean)
            scr.script = p
            return scr
        })
    }

    const elementAddedOrDeleted = () => {
        const scriptsLength = Math.max(...allScripts.map(el => el.script ? el.script.length : 0))
        if (((elements.length > scriptsLength) || (elements.length < scriptsLength))) {
            return false
        } else if (removeElement && removeElement.length > 0) {
            return false
        } else {
            if (scriptPublished)
                return true
            else
                return false
        }
    }

    const getColorForEdge = (edgeInfo, style, target, source, id) => {
        let latestElem;
        setElements(currentState => { // Do not change the state by getting the updated state
            latestElem = currentState
            return currentState;
        })

        const findTheTargetNode = latestElem ? [...latestElem].filter(e => e.id == target)[0] : [...elements].filter(e => e.id == target)[0]
        let g = findTheTargetNode && colorCodeOutcomes.find(e => e.title == findTheTargetNode.data.heading)
        if (g) {
            return `react-flow__edge-path ${g.color}`
        }
        if (findTheTargetNode && findTheTargetNode.data.heading == 'Introduction')
            return `react-flow__edge-path introduction`
        else if (findTheTargetNode && findTheTargetNode.data.heading == 'Closing')
            return `react-flow__edge-path closing`
        else if (findTheTargetNode && findTheTargetNode.data.heading == 'Quick Pitch')
            return `react-flow__edge-path quick_pitch`
        else if (findTheTargetNode && findTheTargetNode.data.heading == 'Main Pitch')
            return `react-flow__edge-path main_pitch`
        else
            return `react-flow__edge-path`
    }

    const updateElementPosition = () => {
        const updatedElementsPosition = elements.map(({ id, position }) => {
            if (position) {
                let arr = [id, position]
                return arr
            }
        }).filter(Boolean)
        const entries = new Map(updatedElementsPosition);
        const obj = Object.fromEntries(entries);
        formDataToSend.map(form => {
            form.script.map(elem => {
                delete elem.xPos
                delete elem.yPos
                let result = elem.position ? elem.position = obj[elem.id] : elem
                return result
            })
        })
        return formDataToSend
    }

    const switchIS = (e, name) => {
        let result = allScripts.filter(scr => {
            if (scr.scriptLanguage == name) {
                setActiveLang(name)
                return scr
            }
        })

        if (!result[0]) {
            alert(`Script is not created for ${name} language`)
            return
        } else {
            setElements(result[0].script)
            setElements1([...result[0].script])
            setAllScripts2(result[0].script)
        }
    }

    const publishScript = async (e) => {
        //check if the outcome is empty
		const checking = elements && elements.length > 0 && elements.filter(each => {
			if(each.data && (each.data.outcome == 'outcome' || each.data.label == '')){
				return each
			}
		})

		if(checking && checking.length > 0){
			toast(`${checking.length} edges are not linked to the outcome or the edge label is not mentioned`)
			return false
		}
        e.preventDefault()
        msgStatus.script.status = ''
        msgStatus.practice.status = ''
        msgStatus.script.msg = ''
        msgStatus.practice.msg = ''

        setMsgStatus({ ...msgStatus })

        const getTheSelectedOption = publishOption.filter(option => option.checked)
        const isScriptPublishSelected = getTheSelectedOption.some(op => op.name == 'script')
        const isScriptPracticeSelected = getTheSelectedOption.some(op => op.name == 'practice')

        let result = await publishTheScript(isScriptPublishSelected, isScriptPracticeSelected)
        if (result) {
            setApiMsg(true)
        }
    }

    const updateFaq = (e) => {
        e.preventDefault();
        if (faqs.length > 0)
            ApiActions.updateFaq(dataForFaq).then(resp => {
                toast("FAQ updated")
            }).catch(err => {
                toast("Error")
            })
        else
            toast("Something seems wrong with the CSV format")
    }

    const setCsv = (e) => {
        var file = e.target.files[0];
        var reader = new FileReader();
        reader.onload = (e) => {
            processCsvFile(e.target.result)
        }
        reader.readAsText(file);
    }

    const processCsvFile = (result) => {
        var { data } = Papa.parse(result, { header: true });
        if (data[0].question) {
            data.map((faq, id) => {
                Object.keys(faq).map((key, idx) => {
                    if (!faq["question"] || !faq["answer"]) {
                        data.splice(id, 1)
                    }
                })
            })
            var dataTemp = data.map((datum, idx) => {
                if (datum.question && datum.answer)
                    return {
                        "question": datum.question,
                        "answer": datum.answer
                    }
            })
        }

        // 1. question, answer
        let dataToSendFaq = {
            "teleproject": teleproject,
            "faqs": dataTemp || []
        }
        setFaqApi(dataToSendFaq)
        setFaqs(dataTemp || [])
    }

    const openScriptInNewTab = (url) => {
        let newWnd = window.open(url, '_blank');
        newWnd.opener = null
    }

    const copyToClipboard = () => {
        let el = document.querySelector('.share-link')
        el.select()
        document.execCommand('copy');
        setIsLinkCopied(true)
    }

    const renderLinkShareModal = () => {
        let params = {}
        let history = window.location.href.split('/').reverse()[0]
        let hosturl = Api.getBaseURL(params).includes('staging') ? 'https://staging.business.futwork.com' : 'https://business.futwork.com'
        return (
            <FrappModal
                onCloseButton={true}
                className="tele-payment-modal"
                show={showLinkShareModal}
                closeButtonClicked={() => {
                    setShowLinkShareModal(false)
                    setIsLinkCopied(false)
                }}
            >
                <div className="notif-popup">
                    <div className="heading">Share Preview</div>
                    <div className="text">
                        <FormControl
                            id="input-edit"
                            className='share-link'
                            value={`${hosturl}/script-preview/${history}`}
                            required
                            readOnly={true}
                            type="text"
                        />
                        {isLinkCopied ? <span style={{ color: '#023349', fontSize: '14px' }}>Link copied !</span> : null}
                    </div>

                </div>
                <br />
                <div class='shareModalBtnSection'>
                    <FrappButton className="submit" type="submit" id="new-btn" handler={(e) => copyToClipboard(e)}>
                        Copy link
                 </FrappButton>
                    <FrappButton className="submit" type="submit" id="new-btn" handler={() => openScriptInNewTab(`${hosturl}/script-preview/${history}`)}>
                        Open the preview
                 </FrappButton>
                </div>
            </FrappModal >
        )
    }

    const showMsg = () => {
        return (
            <div className="duplicate-si-result">
                <p>{msgStatus.script.status == 'success' ? <span>{msgStatus.script.msg}</span> : <span style={{ color: '#DD5858' }}>{msgStatus.script.msg}</span>}</p>
                <p>{msgStatus.practice.status == 'success' ? <span>{msgStatus.practice.msg}</span> : <span style={{ color: '#DD5858' }}>{msgStatus.practice.msg}</span>}</p>
            </div>
        )
    }

    const renderPublishModal = () => {
        let params = {}
        let history = window.location.href.split('/').reverse()[0]
        let hosturl = Api.getBaseURL(params).includes('staging') ? 'https://staging.business.futwork.com' : 'https://business.futwork.com'

        const checkForValidation = () => {
            return !publishOption.some(op => op.name == 'script' && op.checked)
        }

        return (
            <FrappModal
                onCloseButton={true}
                //  className="tele-payment-modal"
                show={showPublishModal}
                bsSize={'sm'}
                closeButtonClicked={() => {
                    setShowPublishModal(false)
                    setApiMsg('')
                    publishOption.map(each => each.checked = false)
                    setPublishOption([...publishOption])
                }}
            >
                <div className="notif-popup">
                    <div className="heading" style={{ marginBottom: '3%' }}>Publish</div>
                    <div className='text' style={{ display: 'flex', flexFlow: 'column' }}>
                        {
                            publishOption.map((each, i) => {
                                return publishOption[i].name == 'practice' && scriptPublished == false ?
                                    <label key={i}><input type="checkbox"
                                        // disabled={scriptPublished == false ? true : false} 
                                        checked={each.checked} onChange={() => { publishOption[i].checked = !publishOption[i].checked; setPublishOption([...publishOption]) }} /> <span>{each.label}</span></label>
                                    // <CustomTooltip placement={'bottom'} description={'Practice can be published only if script published'}
                                    //     component={<label><input type="checkbox" disabled={scriptPublished == false ? true : false} checked={each.checked} onChange={() => { publishOption[i].checked = !publishOption[i].checked; setPublishOption([...publishOption]) }} /> <span>{each.label}</span></label>
                                    //     }>
                                    // </CustomTooltip>
                                    : <label key={i}><input type="checkbox" checked={each.checked} onChange={() => { publishOption[i].checked = !publishOption[i].checked; setPublishOption([...publishOption]) }} /> <span>{each.label}</span></label>

                                // <label><input type="checkbox" disabled={publishOption[i].name == 'practice' ? true : false} checked={each.checked} onChange={()=>{publishOption[i].checked = !publishOption[i].checked;setPublishOption([...publishOption])}}/> <span>{each.label}</span></label>
                            })
                        }
                    </div>

                </div>
                <br />
                {apiMsg ? showMsg() : null}
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <FrappButton className="submit" disabled={checkForValidation()} id="new-btn" handler={(e) => publishScript(e)}>
                        CONFIRM
                 </FrappButton>
                </div>
            </FrappModal >
        )
    }

    const formQuestion = (data) => {
        setFormData(data)
        chooseDCLang(data, data[0])
    }

    const closeDCPopup = () => {
        setDCModal(false)
    }

    const [data, setData] = useState('')

    const chooseDCLang = (formData, e) => {
        const changeKeyName = (data) => {
            if (data.nodeData.data.form) {
                data.nodeData.data.form = data.nodeData.data.form.map(each => {
                    const { inputType: questionType, label: question, ...others } = each;
                    const renamedPropsObject = { questionType, question, ...others }
                    each = renamedPropsObject
                    return each
                })
            }
            return data
        }

        formData.filter(form => {
            if (form.name == e.name) {
                // setData(changeKeyName(form))
                setData(form)
            }
        })
    }

    const renderDataCollectionModal = () => {
        return <FrappModal
            bsSize={'large'}
            onCloseButton={true}
            className="caller-audit-modal modal-scrollbox"
            show={showDCModal}
            closeButtonClicked={() => {
                setFormData('')
                setDCModal(false)
            }
            }
        >
            <div className="caller-qc">
                <div className="heading">Data Collection</div>
                {/* {formRender()} */}
                <DataCollectionForm allScripts2={allScripts2} formsDataCollection={data} formData={formData} closeDCPopup={closeDCPopup} />
            </div>
        </FrappModal>
    }

    const renderActionTriggerModal = () => {
        return <FrappModal
            bsSize={'large'}
            onCloseButton={true}
            className="caller-audit-modal modal-scrollbox"
            show={showActionTrigger}
            closeButtonClicked={() => {
                setFormData('')
                setShowActionTrigger(false)
            }
            }
        >
            <div className="caller-qc">
            <div className="heading">Trigger Action</div>
            <TriggerActionForm allScripts2={allScripts2} formsDataCollection={data} formData={formData} closeDCPopup={closeDCPopup} taskProps={taskProps}/>
            </div>
        </FrappModal>
    }

    const getToast = (data) => {
        toast(data)
    }
    const renderLabelModal = () => {
        let scriptHeadings = (allScripts[0]  && allScripts[0].scriptHeadings &&
        allScripts[0].scriptHeadings.map(each => {
			return {
				label: each,
				value: each,
                isSaved: true
			}
		})) || [];

        return (
        <Popup
        show={showAddLabel}
        closePopup={()=>{setFormData('');setShowAddLabel(false)}}
        size='sm'
        heading='Add / Edit label'
        >
        <LabelCreation labels={scriptHeadings} getToast={getToast}/>
        </Popup>
        )
    }

    const renderScriptPreview = () => {
        return(
            <Popup
                show={showISPreview}
                closePopup={() => setShowISPreview(false)}
                size='sm'
                heading='IS Preview'
            >
                <div className="faq-popup">
                    <div><PreviewInteractiveScript script={scriptPreview} projectTitle={teleprojectTitle.toString()} faqs={isFaq} allScripts={allScripts} teleprojectdata={teleprojectdata}/></div>
                    <div className='btn-footer'>
                        <div className='script-link-wrapper'>
                            <span>Script</span>
                            <a href='#' onClick={() => {
                                let newWnd = window.open(`${scriptDocLink}`, '_blank')
                                newWnd.opener = null
                            }}>Script Link</a>
                        </div>
                    </div>
                </div>
            </Popup>
        )
    }

    const renderDeleteScriptPopup = () => {

     

        const handleDeleteValidation = (e) => {
            if (e.target.value === 'DELETE') setShowDeleteButton(true)
            else setShowDeleteButton(false)
        }

        const deleteScripts = async () => {
            try {
                let result = await ApiActions.deleteScript(
                    teleproject
                );

                if(result.status == 200){
                    const { data : {result: {n}}} = result
                    setDeleteScriptMsg({
                        type: 'success',
                        msg: `The script is deleted for ${n} language(s)`
                    })
                    setTimeout(()=>{
                        window.location.reload()
                    },1500)
                }
            } catch (error) {
                console.error(error)
                let msg = ''
                if (error.response)
                    msg = error.response && error.response.data[0] && error.response.data[0].message || "Unexpected Error!"
                else
                    msg = error.message || "Unexpected Error!"

                setDeleteScriptMsg({
                        type: 'error',
                        msg: msg
                })
            }
        }

        return(
            <Popup
            show={showDeleteScriptPopup}
            closePopup={()=>{
                setShowDeleteScriptPopup(false)
                setShowDeleteButton(false)
                setDeleteScriptMsg({type: '', msg: ''})
            }}
            size='sm'
            heading={'Delete Script(s)'}
            popupContent={
                    allScripts && allScripts.length > 0 ?
                    
                        deleteScriptMsg && deleteScriptMsg.msg ? 
                        <div className={deleteScriptMsg.type == 'success' ? 
                        'success-msg' : 'failure-msg'}>{deleteScriptMsg.msg}</div>
                        :
                        <>
                        <div style={{marginBottom: '1em'}}>Type 'DELETE' to <strong>permanently</strong> delete the script(s)</div>
                        <input type='text' className='singleLineInput' placeholder='Type here' onChange={handleDeleteValidation}/>
                        </>
                        :
                    <div>Script does not exist for this project</div>
            }
            popupFooter={showDeleteButton && !deleteScriptMsg.msg ? <FutworkButton buttonStyle='danger--solid'
            onClick={() => deleteScripts()}
            >DELETE</FutworkButton> : null}
            footerAlign='center'
             />
        )
    }

    const renderFAQPopup = () => {
        const TabGroup = withTabGroup(Tab, 'group');
        const languagesList = teleprojectdata.languages.map(lang => lang.name)
        return(
            <Popup show={showFAQPopup} closePopup={()=> {
                
                setShowFAQPopup(false)
            }}
            size='lg' 
            heading = {
                <div>
                <div>FAQ's</div>
                <div style={{fontSize: '16px'}}>
                    <TabGroup tabs={languagesList} color={'navy-blue-tab-bottom-border'} 
                    onClick={(value) => setActiveFaqLanguage(value)}
                    activeTab={faqActiveLanguage}
                    />
                </div>
                </div>
            }
            stickyHeader={true}
            >
                <Faq faqActiveLanguage={faqActiveLanguage}/>

                {/* old faqs code */}
                {/* <div className="heading-faq">FAQs</div> */}

                {/* <div className="faq-popup">
                <div className="download-faq">

                <CSVLink data={faqs} filename={`${teleprojectTitle}.csv`}><div className="download-btn">Download <img title="Download CSV" src={require("../../../assets/images/Icons_download_01.svg")} /></div>
                </CSVLink>
                </div>
                <div className="grey-box">
                <div className="upload-text">Upload CSV</div>
                <input type="file" id="faqcsv" onChange={(e) => setCsv(e)} />
                </div>

                <button className="faq-btn" onClick={updateFaq}> Submit</button>
                </div> */}
            </Popup>
        )
    }

    const checkIfScriptValid = () => {
        return isDataPresent ? true : false
    }

    return isLoading ? <Loader />
        : <div>
            <div className="script-header">
                <div className="script-heaing">
                    <img src={require("../../../assets/images/Icons_arrow.svg")} onClick={() => { props.history.push("/interactive-script") }}/>
                    {<h4>{teleprojectTitle.toString()}</h4>}
                    {unSaved && <span>UnSaved</span>}
                    {scriptPublished ? <div className="live-script"><img src={require("./../../../assets/images/greendot.png")} /><div className="live"> Live</div></div> : null}
                    {practicePublished ? <div className="live-script"><img src={require("./../../../assets/images/greendot.png")} /><div className="live"> Practice (Live)</div></div> : null}
                </div>
                {scriptPreview.length > 0 ? <button className="save-btn" onClick={() => setShowLinkShareModal(true)}> Share </button> : null}
                <button className="publish-btn" onClick={() => setShowISPreview(true)}> Preview </button>
                {/* <button  className="save-btn"> Save </button> */}
                <button className="publish-btn" onClick={() => setShowPublishModal(true)} disabled={checkIfScriptValid()}> Publish </button>
                <button className="publish-btn delete" onClick={() => setShowDeleteScriptPopup(true)}> Delete </button>
            </div>


            <div className="i-wrap">
                <ToastContainer draggable={true} />
                <ReactFlowProvider>
                    {/* toolbar */}
                    <div className="toolbar-script">
                        <div className="wrapper-i">

                            <div className="card-wrap" onDragStart={(event) => onDragStart(event, 'input')} draggable>
                                <div className="card-false" >
                                    <img src={require("../../../assets/images/int_script_toolbar/Icons_start-end.svg")} />
                                </div>
                                <div className="label-i">Start/End</div>
                            </div>

                            <div className="card-wrap" onDragStart={(event) => onDragStart(event, 'default')} draggable>
                                <div className="card-false">
                                    <img src={require("../../../assets/images/int_script_toolbar/Icons_text_block.svg")} />
                                </div>
                                <div className="label-i">Text</div>
                            </div>

                            {/* <div className="card-wrap" onDragStart={(event) => onDragStart(event, 'default')} draggable>
                                <div className="card-false">
                                    <img src={require("../../../assets/images/int_script_toolbar/Icons_quick_pitch.svg")} />
                                </div>
                                <div className="label-i">Quick Pitch</div>
                            </div>

                            <div className="card-wrap" onDragStart={(event) => onDragStart(event, 'default')} draggable>
                                <div className="card-false">
                                    <img src={require("../../../assets/images/int_script_toolbar/Icons_additional_responses.svg")} />
                                </div>
                                <div className="label-i">DataCollection</div>
                            </div> */}

                            <div className="card-wrap" onClick={() => setOutcomeTrue(!outcomeFlag)}>
                                <div className={`card-${outcomeFlag}`}>
                                    <img className="un-dragable" src={require("../../../assets/images/int_script_toolbar/Icons_outcome.svg")} />
                                </div>
                                <div className="label-i">Outcomes</div>
                            </div>

                            <div className="card-wrap"  onClick={()=>setShowFAQPopup(true)}>
                                <div className="card-false">
                                    <img className="un-dragable" src={require("../../../assets/images/int_script_toolbar/Icons_faqs.svg")} />
                                </div>
                                <div className="label-i">FAQs</div>
                            </div>

                            <div className='language-listing'>
                                <p className='lang-header'>LANGUAGE</p>
                                {
                                    teleprojectdata.languages && teleprojectdata.languages.length > 0 && teleprojectdata.languages.map(lang => {
                                        return <div className={`list ${lang.name == activeLang && 'active'}`} key={lang.name}><span className='name' onClick={(e) => switchIS(e, lang.name)}>{lang.name}</span><span className='default'>{lang.default ? 'Default' : null}</span></div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    {/* end of toolbar */}

                    {/* flow diagram */}
                    {allScripts && allScripts.length > 0 && allScripts2 && allScripts2.length > 0 ?
                        <div className="flow" ref={reactFlowWrapper}>
                            <ReactFlow
                                elements={elements}
                                nodeTypes={nodeTypes}
                                edgeTypes={edgeTypes}
                                onLoad={onLoad}
                                style={{ width: '100%', minHeight: '90vh' }}
                                onConnect={onConnect}
                                connectionLineStyle={{ stroke: "#ddd", strokeWidth: 2 }}
                                connectionLineType="step"
                                snapToGrid={true}
                                onEdgeUpdate={onEdgeUpdate}
                                snapGrid={[16, 16]}
                                onDrop={onDrop}
                                onDragOver={onDragOver}
                                onElementsRemove={onElementsRemove}
                                onNodeDragStop={onNodeDragStop}
                                onNodeDrag={onNodeDrag}
                            >
                                <Background
                                    color="#888"
                                    gap={16}
                                />
                                <Controls />

                            </ReactFlow>
                        </div>
                        :
                        !isDataPresent ? null :
                            <div className="flow" ref={reactFlowWrapper}>
                                <ReactFlow
                                    elements={elements}
                                    nodeTypes={nodeTypes}
                                    edgeTypes={edgeTypes}
                                    onLoad={onLoad}
                                    style={{ width: '100%', minHeight: '90vh' }}
                                    onConnect={onConnect}
                                    connectionLineStyle={{ stroke: "#ddd", strokeWidth: 2 }}
                                    connectionLineType="step"
                                    snapToGrid={true}
                                    onEdgeUpdate={onEdgeUpdate}
                                    snapGrid={[16, 16]}
                                    onDrop={onDrop}
                                    onDragOver={onDragOver}
                                    onElementsRemove={onElementsRemove}
                                    onNodeDragStop={onNodeDragStop}
                                    onNodeDrag={onNodeDrag}
                                >
                                    <Background
                                        color="#888"
                                        gap={16}
                                    />
                                    <Controls />
                                </ReactFlow>
                            </div>
                    }
                    {/* end of flow diagram */}
                    {
                        crud ?
                            <Editor nodeEditor={'nodeEditor'} removeDeletedElements={removeDeletedElements} updateElementPosition={updateElementPosition} setDCModal={setDCModal} showDCModal={showDCModal} setShowActionTrigger={setShowActionTrigger} showActionTrigger={showActionTrigger} formQuestion={formQuestion} elementAddedOrDeleted={elementAddedOrDeleted} 
                            showAddLabel={showAddLabel} setShowAddLabel={setShowAddLabel}/>
                            : showEdgeForm ?
                                <Editor edgeEditor={'edgeEditor'} removeDeletedElements={removeDeletedElements} updateElementPosition={updateElementPosition} elementAddedOrDeleted={elementAddedOrDeleted} />
                                : null
                    }
                </ReactFlowProvider>
            </div>
            {showLinkShareModal ? renderLinkShareModal() : null}
            {showPublishModal ? renderPublishModal() : null}
            {showDCModal ? renderDataCollectionModal() : null}
            {showActionTrigger ? renderActionTriggerModal() : null}
            {showAddLabel ? renderLabelModal() : null}
            {showISPreview ? renderScriptPreview() : null}
            {showDeleteScriptPopup ? renderDeleteScriptPopup() : null}
            {showFAQPopup ? renderFAQPopup() : null}
        </div>
}

export default GraphDiagram