import React, { useState, useEffect, Fragment } from 'react'
import styles from '../../assets/stylesheets/pages/hiring/leads.module.scss'
import FutworkButton from '../../uicomponents/button'
import { transferOpenLeadsToManyAgents, transferLeadsById, uploadVideo } from './service'
import { InfoIcon, TrashIcon } from '../../assets/images/index'
import checkIsEmpty from "../../helper/checkIsEmpty"
import Dropdown from 'react-dropdown'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faFileCsv } from '@fortawesome/free-solid-svg-icons'
import useApi from '../../hooks/useApi'
import Upload from "../../uicomponents/upload/upload";
import Capitalise from  '../../helper/Capitalise'
import Papa from "papaparse"

export const TransferAsPerCaller = (props) => {
    const { callerData: { firstname, lastname, mobile, userpoolOpenLeadsCount }, restOfAgents = [],
        teleprojectId } = props
    const [selectAll, setSelectAll] = useState(false)
    const [filteredResult, setFilteredResult] = useState([])
    const [search, setSearch] = useState('')
    const [tempAgentsList, setTempAgentList] = useState(restOfAgents && restOfAgents.map(agent => {
        return {
            ...agent,
            checked: false
        }
    }))
    const [apiMsg, setApiMsg] = useState({ type: '', msg: '' })
    const [leadCountToTransfer, setLeadCountToTransfer] = useState(userpoolOpenLeadsCount)

    useEffect(() => {
        if (selectAll) {
            setTempAgentList(restOfAgents && restOfAgents.map(agent => {
                return {
                    ...agent,
                    checked: true
                }
            }))
        } else {
            setTempAgentList(restOfAgents && restOfAgents.map(agent => {
                return {
                    ...agent,
                    checked: false
                }
            }))
        }
    }, [selectAll])

    const resetList = () => {
        setTempAgentList(restOfAgents && restOfAgents.map(agent => {
            return {
                ...agent,
                checked: false
            }
        }))
        setSelectAll(false)
        setApiMsg({ type: '', msg: '' })
    }

    const searchAgents = (value) => {
        setSearch(value)
        if (value) {
            let filteredList = tempAgentsList && tempAgentsList.filter(agent => {
                if ((agent.lastname && agent.lastname.toLowerCase().includes(value)) || (agent.firstname && agent.firstname.toLowerCase().includes(value))) return true
            })
            setFilteredResult(filteredList)
        } else setFilteredResult([])
    }

    const toggleCallerSelection = (checked, agent) => {
        setFilteredResult(filteredResult && filteredResult.map(item => {
            if (item.id == agent.id) item.checked = checked
            return item
        }))
        setTempAgentList(tempAgentsList && tempAgentsList.map(item => {
            if (item.id == agent.id) item.checked = checked
            return item
        }))
    }

    const transferLeads = async () => {
        const payload = {
            teleproject: teleprojectId,
            fromUserMobile: mobile,
            toUserMobiles: tempAgentsList && tempAgentsList.filter(({ checked }) => checked).map(({ mobile }) => mobile) || []
        }

        try {
            let result = await transferOpenLeadsToManyAgents(payload)
            if (result.data) {
                if (result.status == 200) {
                    let getUserIdsForLeadsAssignedToCallers = result.data.data &&
                        result.data.data.map(({ leadsAssigned, userMobile }) => {
                            if (leadsAssigned >= 1) {
                                return {
                                    userMobile,
                                    leadsAssigned
                                }
                            }
                        }).filter(Boolean)
                    setLeadCountToTransfer(leadCountToTransfer - (getUserIdsForLeadsAssignedToCallers.reduce((acc, currVal) => acc + currVal.leadsAssigned, 0)))
                    const updateLeadsCount = tempAgentsList.map(agent => {
                        let isFound = getUserIdsForLeadsAssignedToCallers && getUserIdsForLeadsAssignedToCallers.find(({ userMobile }) => userMobile == agent.mobile)
                        if (isFound) agent.userpoolOpenLeadsCount = agent.userpoolOpenLeadsCount + isFound.leadsAssigned
                        return agent
                    })

                    setTempAgentList(updateLeadsCount)
                    setApiMsg({
                        type: 'success',
                        msg: `The leads are transferred and the open lead count is updated in the above list for the selected callers`
                    })
                }
            }
        } catch (err) {
            setApiMsg({
                type: 'failure',
                msg: err.response ? (err.response && err.response.data && err.response.data.error) || "Unexpected Error!"
                    : err.message || "Unexpected Error!"
            })
            console.log(err.response)
        }
    }

    return (
        <div>
            <div className={styles.select_agent_info}>
                <div>Caller: {firstname} {lastname}</div>
                <div>Open leads: {leadCountToTransfer}</div>
            </div>

            <div className={styles.select_agent_info}>
                {tempAgentsList && tempAgentsList.filter(({ checked }) => checked).length} caller's selected
            </div>

            <div className={styles.search_agent_wrapper}>
                <input type='search' placeholder='Search by agent name' className={styles.search_agent}
                    onChange={(e) => searchAgents(e.target.value)} />
                <div className={styles.agent_list}>
                    {
                        tempAgentsList && tempAgentsList.length <= 0 ?
                            <p className={styles.align_text}>No data</p>
                            :
                            <table>
                                <thead>
                                    <tr>
                                        <th><input type='checkbox' onChange={(e) => e.target.checked ? setSelectAll(true) : setSelectAll(false)}
                                            checked={selectAll} /></th>
                                        <th>Caller</th>
                                        <th>Open leads</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        (search !== '' ? filteredResult : tempAgentsList).map((agent, index) => {
                                            return <tr key={index}>
                                                <td><input type='checkbox' checked={agent.checked}
                                                    onChange={(e) => toggleCallerSelection(e.target.checked, agent)} /></td>
                                                <td>{agent.firstname} {agent.lastname}</td>
                                                <td>{agent.userpoolOpenLeadsCount}</td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </table>
                    }
                </div>
            </div>

            <div className={styles.info_bar}>
                <img src={InfoIcon} />{userpoolOpenLeadsCount} leads will be assigned equally among the selected callers from the above list.
            </div>

            {
                !checkIsEmpty(apiMsg) ?
                    <div className={apiMsg.type == 'success' ?
                        styles['success-msg'] : styles['error-msg']}>{apiMsg.msg}</div>
                    : null
            }

            <div className={styles.lead_action_wrapper}>
                <FutworkButton buttonSize='sm w-100' buttonStyle='navy--outline non-rounded' onClick={() => resetList()}>CANCEL</FutworkButton>
                <FutworkButton buttonSize='sm w-100' buttonStyle='primary--outline non-rounded' onClick={() => transferLeads()}>TRANSFER</FutworkButton>
            </div>

        </div>
    )
}

export const TransferById = (props) => {
    const {callers} = props

    const getStatus = (status) => {
        switch (status) {
            case "onhold":
                return "On Hold"
                break;
            case "inprogress":
                return "Selected"
            case "completed":
                return "Completed"
            case "quit":
                return "Quit"
            case "training":
                return "Training"
            default:
                break;
        }
    }

    let options = callers && callers.map(caller => {
        return {label: `${caller.firstname} ${caller.lastname} (${caller.mobile}) - ${getStatus(caller.status)}`, value: caller.id}
    })

    const [isCsvUploaded, setIsCsvUploaded] = useState(false)
    const [file, setFile] = useState('')
    const [fileLimit, setFileLimit] = useState(false)
    const [apiMsg, setApiMsg] = useState({ type: '', msg: '' })
    const [callerSelected, setCallerSelected] = useState('')

    const transferLeadsByIdApi = useApi(transferLeadsById)
    const uploadVideoApi = useApi(uploadVideo)

    const changeHandler = async(e) => {
        setApiMsg({ type: '', msg: '' })
        setFileLimit(false)
        const file = e.target.files[0]
        if (file && file.name && file.name.match(/\.(csv)$/)) {
            if (e.target.files.length > 0) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    setFile(file)
                    Papa.parse(file, {
                        complete: (result) => {
                          // The parsed data is available in the `data` property
                        if(result.data && result.data.length > 10000){
                            setFileLimit(true)
                        }
                         // Extract and set column headers
                        const headers = result.meta.fields || [];
                        const [first] = headers

                        if(first == 'lead id'){
                            // setLeadHeader(true)
                        }else{
                            setApiMsg({
                            type: 'error',
                            msg: 'CSV should have column header as "lead id"'
                            })
                            return false
                        }
                        },
                        header: true, // If the CSV has a header row
                        dynamicTyping: true, // Convert numerical values to numbers
                      });
                  };
                  // Read the file as text
                  reader.readAsText(file);
               }
        }else{
            setApiMsg({
                type: 'error',
                msg: 'Only a file with .csv extension can be uploaded'
            })
        }
    }

    const uploadCsv = () => {
        if(!callerSelected){
            setApiMsg({
                type: 'error',
                msg: 'Please select the agent to transfer leads'
            })
            return false
        }

        if(!file){
            setApiMsg({
                type: 'error',
                msg: 'Please select the CSV file to upload'
            })
            return false
        }
        if(fileLimit){
            setApiMsg({
                type: 'error',
                msg: 'File limit exceeded'
            })
            return false
        }
       
        setApiMsg({
            type: '',
            msg: ''
        })
        const formData = new FormData();
        formData.append('teleproject', props.teleprojectId);
        formData.append('user', callerSelected);
        formData.append('file', file);
        transferLeadsByIdApi.request(formData)
    }


    const { data, loading, error } = transferLeadsByIdApi || {}


    useEffect(() => {
        if (error) {
            console.error('error==', error)
            setApiMsg({
                type: 'error',
                msg: error && error.code ? error.error : error && error.message ? error.message : 'Unexpected error'
            })
        }

        if (data) {
            setApiMsg({
                type: 'success',
                msg: data && data.data && data.data.message
            })
        }
    }, [error, data])

    return(
        <div className={styles.transfer_by_id}>
     
            <div className={styles.wrapper}>
            <Dropdown options={options}
            onChange={(val) => {
                setCallerSelected(val.value)
                setApiMsg({type: '', msg: ''})
            }}
            placeholder="Select caller / agent" />
            <div className={[styles.upload_csv, styles.dotted_border].join(' ')}>
                <Upload placeholder='Select CSV file to upload' onChange={(e) => changeHandler(e)} />
                <div>
                <div className={styles.text_muted}>Note: The CSV should have the first column as "lead id"</div>
                <div className={styles.text_muted}>File limit 10000 entries</div>
                </div>
    
                <FutworkButton buttonSize='sm' buttonStyle='navy--solid non-rounded' 
                onClick={uploadCsv}>Upload</FutworkButton>
            </div>
            {
                !checkIsEmpty(apiMsg) ?
                    <div className={apiMsg.type == 'success' ?
                        styles['success-msg'] : styles['error-msg']}>{Capitalise(apiMsg.msg)}</div>
                    : null
            }
            </div>
        </div>
    )
}

// export default {
//     transferAsPerCaller,
//     LeadsTransfer
// }

