import React, {useState, useEffect, useRef} from 'react'
import { ToastContainer, toast } from "react-toastify";
import Dropdown from 'react-dropdown';
import {SingleDatePicker} from "react-dates";
import moment from 'moment';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import FormControl from 'react-bootstrap/lib/FormControl';
//import custom components
import FrappButton from '../utilities/FrappButton';
import FrappModal from "../utilities/FrappModal";
import ApiActions from '../../actions/ApiActions';
import CustomTab from '../utilities/CustomTab';
import Tab from '../../uicomponents/tab'
import Heading from '../../uicomponents/heading'
import withTabGroup from '../../hocs/withTabGroup'
import Table from '../../uicomponents/table/Table'
import capitalise from '../../helper/Capitalise'
import {AlertIcon} from '../../assets/images'
import Tooltip from 'react-bootstrap/lib/Tooltip'
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger'
import DropdownButton from 'react-bootstrap/lib/DropdownButton';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import generatePayload from '../../helper/generatePayloadForTable';
import ProjectQmf from '../../pages/audit/qmf/projectQmf';
import AuditRule1ACall from './auditRule1Call'
import { TrackHistory } from '../../assets/images';
import CustomTooltip from '../utilities/ToolTip';
import CallHistory from '../../pages/hiring/callHistory';
import Popup from '../../uicomponents/popup';
import useApi from '../../hooks/useApi';
import ThreeDotLoader from '../utilities/ThreeDotLoader';
const TABS = [
    'Winning Outcome',
    'High HT',
    'Self Disconnection',
    'Short Calls',
    // 'MinHT for winning outcome',
    'Auto hold calls for audit'
]

const QualityCompliance = (props) => {

    const TabGroup = withTabGroup(Tab, 'group');

    // Tabs in the popup
    const tabsForStage = [
        {
            label: 'Call Action',
            value: 'caller_action'
        },
        {
            label: 'Caller Action',
            value: 'cmm'
        },
        {
            label: 'Caller Compliance History',
            value: 'compliance_history'
        },
        {
            label: 'CMM Action History',
            value: 'cmm_action_history'
        }
    ]
   //default options to be shown inside the checkbox list for feedbacks.
    const defaultFeedbacks = [
        {label: "Rude/Argumentive call", checked: false},
        {label: "Disconnected the call", checked: false},
        {label: "Stretching the call", checked: false},
        {label: "Blank call", checked: false},
        {label: "Abusive on call", checked: false},
        {label: "Misinformation on call", checked: false},
        {label: "Chitchat while on call", checked: false},
        {label: "Personal discussion on call", checked: false}
    ]

    const warningFeedbacks = [
        {label: "Rude/Argumentive call", checked: false},
        {label: "Disconnected the call", checked: false},
        {label: "Stretching the call", checked: false},
        {label: "Blank call", checked: false},
        {label: "Abusive on call", checked: false},
        {label: "Misinformation on call", checked: false},
        {label: "Chitchat while on call", checked: false},
        {label: "Personal discussion on call", checked: false}
    ]

    const retrainFeedbacks = [
        {label: "Caller put the call on hold", checked: false},
        {label: "Incorrect data collected", checked: false},
        {label: "Incorrect brand name pronunciation", checked: false},
        {label: "Improvement needed in pronunciation", checked: false},
        {label: "Not responding to customer on time", checked: false},
        {label: "Script flow not understood", checked: false},
        {label: "Not following the script", checked: false},
        {label: "Incorrect call handling", checked: false},
        {label: "Lack of listening skills", checked: false},
        {label: "Quick pitch not used", checked: false},
        {label: "Low confidence on call", checked: false},
        {label: "Provided wrong information on call", checked: false},
        {label: "Irrelevant conversation on call", checked: false},
        {label: "Fumbling on call", checked: false},
        {label: "Sounding robotic on call", checked: false},
        {label: "Unprofessional", checked: false},
        {label: "Improper greeting and call opening", checked: false},
        {label: "Improper call closing", checked: false},
        {label: "Improper pace/ speed of talking", checked: false},
        {label: "Low energy on call", checked: false},
        {label: "Not allowing customer to speak", checked: false},
        {label: "Poor tone and voice modulation", checked: false},
        {label: "Missing key part of script", checked: false},
        {label: "Call dragging", checked: false},
        {label: "Call objective not understood", checked: false},
        {label: "Not handling FAQs properly", checked: false},
        {label: "Rushing through the script", checked: false},
        {label: "Not switching language based on customer response", checked: false},
        {label: "Situation handling can improve", checked: false},
        {label: "Need product knowledge", checked: false}

    ]

    //creating the reference to the input button for custom feedback
    let customInput = useRef('')
    //creating the reference to the continue button for custom feedback
    let submitBtn = useRef('')
    
    const [teleprojectsdata, setTeleprojectsdata] = useState([])
    const [totalCount, setTotalCount] = useState(0)
    const [query, setQuery] = useState({
        page: 1,
        pageSize: 10
    })
    const [pageChanged, setPageChanged] = useState(true)
    const [isTableLoading, setTableLoading] = useState(false)
    const [skip, setSkip] = useState(0)
    const [limit, setLimit] = useState(10)
    const [searchText, setSearchText] = useState('')
    const [searchMode, setSearchMode] = useState(false)
    const [activeRow, setActiveRow] = useState('')
    const [complianceHistoryTableData, setComplianceHistoryTableData] = useState([])
    const [cmmActionHistoryData, setCmmActionHistoryData] = useState([])
    const [activeTab, setActiveTab] = useState({
            label: tabsForStage[0].label,
            value: tabsForStage[0].value
    })
    const [checkboxFeedbackData,setCheckboxFeedbackData] = useState(defaultFeedbacks)
    const [warningFeedbackData, setWarningFeedbackData] = useState(warningFeedbacks)
    const [retrainFeedbackData, setRetrainFeedbackData] = useState(retrainFeedbacks)
    const [callAction, setCallAction] = useState('')
    const [cmmAction, setCmmAction] = useState('')
    const [isPopupDataLoading, setIsPopupDataLoading] = useState(false)
    const [rule, setRule] = useState('rule2A')
    const [complianceModal, setComplianceModal] = useState(false)
    const [callerAuditModal, setCallerAuditModal] = useState(false)
    const [dataCollectionModal, setDataCollectionModal] = useState(false)
    const [dataCollectionData, setDataCollectionData] = useState('')
    const [reportModal,setReportModal] = useState({
        type: '',
        isOpen: false
    })
    const [emailArray, setEmailArray] = useState([])
    const [defaultStartDate, setDefaultStartDate] = useState(null)
    const [defaultEndDate, setDefaultEndDate] = useState(null)
    const [reportSent, setReportSent] = useState(false)
    const [startDateFocussed, setStartDateFocussed] = useState(false)
    const [endDateFocussed, setEndDateFocussed] = useState(false)
    const [eachEmail, setEachEmail] = useState("")
    const [displayFeedback, setDisplayFeedback] = useState(false)
    const [showCMM, setShowCMM] = useState(false)
    const [callActionFeedback, setCallActionFeedback] = useState([])


    const [currentPage, setCurrentPage] = useState(1);
    const [activeTable, setActiveTable] = useState(TABS[0])
    const [activeTableComplianceHistory, setActiveTableComplianceHistory] = useState(TABS[0])

    const [cmmRules, setCmmRules] = useState('')
    const [behaviourCount, setBehaviourCount] = useState([])
    const [cmmWarningMsg, setCmmwarningMsg] = useState('')

    const [showCallHistory, setShowCallHistory] = useState(false)
    const [selectedRowData, setSelectedRowData] = useState('')


    const [checkboxCallerAuditData, setCheckboxCallerAuditData] = useState([])
    const [checkRetrainReasons, setCheckRetrainReasons] = useState([])

    const getCallHistoryApi = useApi(ApiActions.getCallHistory)

    useEffect(()=>{
        if(activeTable !== 'Auto hold calls for audit'){
        setTableLoading(true)
        ApiActions.getComplianceTableData({
            skip: (currentPage - 1) * limit,
            limit: limit,
            teleproject: searchText ? searchText : null,
            rule: rule
        }).then(resp => {
            setTableLoading(false)
            if(rule == 'rule2A' || rule == 'rule1A') resp && resp.rule1A2A && setTeleprojectsdata([...resp.rule1A2A])
            else resp && resp.calls && setTeleprojectsdata([...resp.calls])
            setTotalCount(resp.total)
        }).catch(err => {
            console.error(err)
            toast("Error");
            setTableLoading(false)
        })
        }
    }, [rule, currentPage, searchText, complianceModal, callerAuditModal == false])


    const {data: callRecords, loading: callRecordLoading} = getCallHistoryApi || {}

    const showPopup = async(rowData,popup) => {
        setActiveRow(rowData)
        switch (popup){
            case "compliance":
                let payload = {
                    userId: rowData.userId,
                    teleprojectId: rowData.teleprojectId
                }
                // setActiveRow(rowData)
                try{
                    let result = await ApiActions.getCmmRules()
                    let behaviourCount = await ApiActions.getBehaviourWiseCount(payload)
                    //cmm rules & behaviour count of the caller are merged to represent it on the feddbacks table
                    if(behaviourCount.status == 200 && result.cmmRules){
                        let formatResult = Object.keys(behaviourCount.data).length > 0 && 
                        Object.keys(result.cmmRules).map((key)=>{
                            return {
                                "feedback_name": key,
                                "count": behaviourCount.data[key],
                                "rules": result.cmmRules[key],
                                "isIncreased": false
                            }
                        })
                        setCmmRules(result.cmmRules)
                        setBehaviourCount(formatResult)
                        setIsPopupDataLoading(false)
                    }
                }catch(err){
                    console.error(err)
                    setIsPopupDataLoading(false)
                }finally{
                    setComplianceModal(true)
                }
            break;
            case "caller_audit":
                let payload1 = {
                    userId: rowData.userId,
                    teleprojectId: rowData.teleprojectId
                }
                setActiveRow(rowData)
                try{
                    let result = await ApiActions.getCmmRules()
                    let behaviourCount = await ApiActions.getBehaviourWiseCount(payload1)
                    //cmm rules & behaviour count of the caller are merged to represent it on the feddbacks table
                    if(behaviourCount.status == 200 && result.cmmRules){
                        let formatResult = Object.keys(behaviourCount.data).length > 0 && 
                        Object.keys(result.cmmRules).map((key)=>{
                            return {
                                "feedback_name": key,
                                "count": behaviourCount.data[key],
                                "rules": result.cmmRules[key],
                                "isIncreased": false
                            }
                        })
                        setCmmRules(result.cmmRules)
                        setBehaviourCount(formatResult)
                        setIsPopupDataLoading(false)
                    }
                }catch(err){
                    console.error(err)
                    setIsPopupDataLoading(false)
                }finally{
                    setCallerAuditModal(true)
                }
            break;
            case "data-collection":
                setDataCollectionModal(true)
                setDataCollectionData(rowData)
            break;
        }
    }

    const resetCompliancePopupState = () => {
        let warningTemp = warningFeedbackData.map(each => {
            return{
                ...each,
                checked: false
            }
        })
        setWarningFeedbackData(warningTemp)
        let retrainTemp = retrainFeedbackData.map(each => {
            return{
                ...each,
                checked: false
            }
        })
        setRetrainFeedbackData(retrainTemp)
        setCallAction('')
        setDisplayFeedback(false)
        setCmmAction('')
        setShowCMM(false)
    }

    const resetCallerAuditPopup = () => {
        setCallAction('')
        setDisplayFeedback(false)
        setCmmAction('')
        setShowCMM(false)
        setCallerAuditModal(false)
        setActiveTab({
            label: tabsForStage[0].label,
            value: tabsForStage[0].value
        })
    }

    const renderReportsModal = () => { 
        const triggerStartDatePicker = () => {
            setStartDateFocussed(true)
        };
        const triggerEndDatePicker = () => {
            setEndDateFocussed(true)
        };
        var startDate = defaultStartDate
            ? moment(new Date(defaultStartDate))
            : null;
        var endDate = defaultEndDate
            ? moment(new Date(defaultEndDate))
            : null;
    

        const addEmailtoArray=(e)=>{
            e.preventDefault();
            if (eachEmail.length > 0)
            emailArray.push(eachEmail);
            setEmailArray([...emailArray])
            setEachEmail('')
        }

        const removeEmailFromArray=(idx)=>{
            emailArray.splice(idx, 1)
            setEmailArray([...emailArray])
       }

        const setReportDate= (date,start) => {
            let tempStartDate,tempEndDate
            if (start) 
            {   tempStartDate = date.format('YYYY-MM-DD');
                setDefaultStartDate(tempStartDate)
            }
            if (!start) 
            {   tempEndDate = date.format('YYYY-MM-DD');
                setDefaultEndDate(tempEndDate)
            }
        }
        const sendReport= async(type)=>{
            let dataToSend;
            dataToSend = {
                startDate: defaultStartDate,
                endDate: defaultEndDate,
                emails: JSON.stringify(emailArray)
            }
            submitBtn.current.startLoading();

            try{
                if(reportModal.type.toLowerCase() == 'audit report'){
                    await ApiActions.getAuditReports(dataToSend).then(resp => {
                        submitBtn.current.stopLoading()
                       setReportSent(true)
                   })
                }
                if(reportModal.type.toLowerCase() == 'cmm action report'){
                    await ApiActions.getCmmReports(dataToSend).then(resp => {
                        submitBtn.current.stopLoading()
                       setReportSent(true)
                   })
                }
            }catch(error){
                   console.error(error.response)
                   submitBtn.current.stopLoading()
            }
        }

         return <FrappModal className="custom-modal" bsSize={'small'} onCloseButton={true} id="report-modal" show={reportModal.isOpen}
         closeButtonClicked={()=>{
            setDefaultStartDate(undefined)
            setDefaultEndDate(undefined)
            setReportSent(false)
            setStartDateFocussed('')
            setEndDateFocussed('')
            setEmailArray([])
            setEachEmail('')
            setReportModal({
                type:'',
                isOpen: false
            })
        }}
         >
             <div className="heading">
                {reportModal.type}
            </div>
             <div className="report-modal">
               <div className="pill-wrapper">
                 {emailArray.map((emails, idx) => {
                   return <div className="pill">
                       {emails}
                       <div className="del-icon" onClick={() => removeEmailFromArray(idx)}>
                         <img src={require("../../assets/images/delete-icon.png")} alt='delete-icon'/>
                       </div>
                     </div>;
                 })}
               </div>
               <div className="email-field">
                 <form style={{ display: "flex", alignItems: "baseline" }} onSubmit={(e) => addEmailtoArray(e)}>
                   <div>
                     <FormGroup>
                       <FormControl placeholder="Enter" name="assign" type="email" value={eachEmail} onChange={(e) => {setEachEmail(e.target.value);setReportSent(false)}} />
                     </FormGroup>
                   </div>
                   <div className="plus-icon">
                     <button type="submit">
                       <img src={require("../../assets/images/plusicon.PNG")} alt='plus-icon'/>
                     </button>
                   </div>
                 </form>
               </div>
                 <div>
                   <div className="date-report">
                     <div name="startDate" onClick={() => triggerStartDatePicker()} id="startdate">
                       <SingleDatePicker noBorder={true} id="startDateReport" date={startDate} 
                       focused={startDateFocussed} 
                       onFocusChange={(focused) => {
                        setStartDateFocussed(focused.focused)
                         }} 
                         placeholder="Select Start Date" 
                         onDateChange={(e) => setReportDate(e, 1)} 
                         displayFormat="DD/MM/YYYY" 
                         numberOfMonths={1}
                        //  isOutsideRange={() => false}
                        isOutsideRange={date => date.isAfter(endDate, 'day')}
                        />
                       <div className="info">Start Date</div>
                     </div>
                     <div className="endDate" onClick={() => triggerEndDatePicker()} id="enddate">
                       <SingleDatePicker noBorder={true} id="endDateReport" date={endDate} 
                       focused={endDateFocussed} 
                       onFocusChange={(focused) => {
                           setEndDateFocussed(focused.focused)
                         }} 
                         placeholder="Select End Date" 
                         onDateChange={(e) => setReportDate(e, 0)} 
                         displayFormat="DD/MM/YYYY" 
                         numberOfMonths={1}
                        //  isOutsideRange={() => false}
                         isOutsideRange={date => date.isBefore(startDate, 'day')}
                         />
                       <div className="info">End Date</div>
                     </div>
                   </div>
                   <p>
                    Remember to press the green plus-icon before you click on 'Send Report'
                    <br />
                    If you enter no email the report will be sent to
                    the default email list.
                    </p>
                 </div>
    
               <div style={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
                 {reportSent ? <div className="success-banner">
                     Preparing your report - you will receive it on email shortly.Here is some <a href="http://www.allolo.ru/" target="_blank" rel="noopener noreferrer">
                       Relaxing Music
                     </a> to listen to till then.
                     <FrappButton className="submit" id="download-report" type="submit" disabled={emailArray && emailArray.length > 0 ? false : true} handler={() => setReportSent(false)}>
                       Send Again
                     </FrappButton>
                   </div> : <FrappButton className="submit" id="download-report" type="submit"
                   disabled={emailArray && emailArray.length > 0 && defaultStartDate && defaultEndDate ? false : true}
                   ref={submitBtn}
                   handler={() => sendReport()} 
                   >
                     Send Report
                   </FrappButton>}
               </div>
             </div>
           </FrappModal>
    }

    const renderCompliancePopup = () => {
        const setActiveTabFn = (activeTab,activeRow) => {
            setActiveTab({
                label: activeTab.label,
                value: activeTab.value
            })
            setActiveTableComplianceHistory(activeTable)
            fetchApiCallsBasedOnStage(activeRow, activeTab)
        }

        const fetchComplianceHistoryData = (userId, rule) => {
            return ApiActions.getComplianceHistoryTableData(userId,rule).then(resp => {
                setComplianceHistoryTableData(resp)
                setIsPopupDataLoading(false)
            }).catch(err => {
                console.error(err)
                setIsPopupDataLoading(false)
                toast(err && err.response ? err.response && err.response.msg : "Error");
            });
        }

        const fetchCmmActionHistory = (userId) => {
            return ApiActions.getCmmActionHistory({userId}).then(resp => {
                setCmmActionHistoryData(resp.data)
                setIsPopupDataLoading(false)
            })
        }

        const fetchApiCallsBasedOnStage = (activeRow, activeTab) => {
            if (activeTab.value == "compliance_history") {
                setIsPopupDataLoading(true)
                // ApiActions.getComplianceHistoryCount(activeRow.userId,'rule2A').then(resp => setComplianceHistoryWinningCount(resp.length))
                // ApiActions.getComplianceHistoryCount(activeRow.userId,'rule3A').then(resp => setComplianceHistoryHTCount(resp.length))
                fetchComplianceHistoryData(activeRow.userId,rule)
            }
            if(activeTab.value == "cmm_action_history"){
                setIsPopupDataLoading(true)
                fetchCmmActionHistory(activeRow.userId)
            }
        }

        const renderContent = (activeTab) => {
            switch (activeTab) {
                case 'caller_action':
                    return callerAction()
                case 'compliance_history':
                    return complianceHistory()
                case 'cmm':
                    return consequenceManagement()
                case 'cmm_action_history':
                    return cmmActionHistory()
                default:
                    break;
            }
        }



        const callerAction = () => {
            let action_options = [
                { label: "Valid",value:"valid"},
                { label: "Invalid",value:"invalid"}
            ]

            //fn to change the state of checked value to true or false
            // based on the checkbox selected ot deselected
            const handleChangeCheckboxFeedback = (event) => {
                let manipulatedData = checkboxFeedbackData.map((item) => {
                    if(item.label === event.target.name) { 
                        item.checked = event.target.checked
                        return item
                    }
                    return item
                })
                setCheckboxFeedbackData(manipulatedData)
            };

            //function to add the custom checkbox to the checkbox list if users hits the 'Enter' key
            const enterPress = (event) => {
                if(event.key === "Enter") {
                    //add the custom feedback to the table
                    let temp = [...behaviourCount]
                    temp = [
                        ...temp,
                        {
                            "feedback_name": event.target.value,
                            "count": 0,
                            "rules": [],
                            "isIncreased": false
                        }
                    ]
                    setBehaviourCount(temp)
                    customInput.current.value = ''
                }
            }

            //fn to render the checkbox by passing it the label and checked attribute got from from array 
            const renderCheckboxFeedback = (checked, label, id, idx) => {
                return (
                    <label key={idx} className='option'>
                     <input type="checkbox"  name={label} checked={checked} onChange={handleChangeCheckboxFeedback}/><span>{label}</span>
                    </label>
                )
            }

            const takeCallAction = () => {
                submitBtn.current.startLoading()
                let tempCheckBox = checkboxFeedbackData.map(each => {
                    return{
                        ...each,
                        checked: false
                    }
                })
                setCheckboxFeedbackData(tempCheckBox)
                // create an object called payload, this object will contain the body that needs
                // to be sent to the api
                let payload = {}
                // condition to check if atleast one checkbox was selected
                let checkSelectedFeedback = checkboxFeedbackData.some((item) => {
                    return item.checked === true
                })
                // if block executed if checkSelectedFeedback is true
                if (callActionFeedback) { 
                    payload= {
                        teletaskHistoryAuditId: activeRow._id,
                        action: callAction,
                        rule: rule,
                        feedbacks: callActionFeedback
                        // if checkSelectedFeedback is true we are adding the feedback key to the payload
                    }           
                }else{
                    // else block executed if checkSelectedFeedback is false
                    // if checkSelectedFeedback is false we will not add the feedback key to the payload
                    payload= {
                        teletaskHistoryAuditId: activeRow._id,
                        rule: rule,
                        action: callAction
                    }
                }
                // if payload is present we are hitting the api
                if(payload){
                    ApiActions.sendComplianceFeedback(payload).then(resp => {
                        toast("Success")
                        submitBtn.current.stopLoading()
                        setShowCMM(true)
                    }).catch(err => {
                        console.error(err.response)
                        toast("Error")
                        submitBtn.current.stopLoading()      
                    })
                }
            }

            const columns = [
                {
                    Header: 'Feedback',
                    accessor: (row) => row.feedback_name,
                    width: '80%',
                    disableSortBy: true
                },
                {
                    Header: 'Count',
                    accessor: (row) => row.count,
                    width: '20%',
                    disableSortBy: true
                }
            ]

            //this function will be truggered everytime the feedback is selected or deselected to add or remove the cmm warning message
            const checkForCmmActionWarning = (rows) => {
                let warningMessage = []
                if(!rows){
                    setCmmwarningMsg(warningMessage)
                    return false
                }
                behaviourCount && behaviourCount.length > 0 && 
                Object.keys(cmmRules).map((key)=> {
                    cmmRules[key].map(rule => {
                        let getCount = rows && rows.find(ob => ob.feedback_name == key)
                        if(getCount && getCount.count >= rule.eligibility){
                            warningMessage.push({
                                action: rule.action,
                                feedback: key
                            })
                        }
                    })
                })
                if(warningMessage && warningMessage.length > 0){
                    setCmmwarningMsg([warningMessage[warningMessage.length-1]])
                }else{
                    setCmmwarningMsg([])
                }        
            }

            //this function will increase or decrease the count on the feedback if selected or deselected
            const feedbackSelected = (rows = []) => {
                if(rows && rows.length > 0){
                    behaviourCount && behaviourCount.map(obj => {
                        const findIndex = rows.find(t => t.feedback_name == obj.feedback_name)
                        if (findIndex && !obj.isIncreased){
                            obj.isIncreased = true
                            obj.count = obj.count + 1
                        }

                        if(!findIndex && obj.isIncreased){
                            obj.isIncreased = false
                            obj.count = obj.count - 1
                        }
                        checkForCmmActionWarning(rows)
                    })
                }else{
                    behaviourCount && behaviourCount.map(obj => {
                        if(obj.isIncreased){
                            obj.isIncreased = false
                            obj.count = obj.count - 1
                        }
                    })
                    checkForCmmActionWarning()
                }
                setCallActionFeedback(rows && rows.map(row => row.feedback_name))
            }

            return <div className='callActionPopup'>
                <div className='actionWrapper'>
                <div className='callActionHeader'>Call Action</div>
            <div className='dropdown-actions '>
                    <Dropdown className='callActionDropdown' 
                    options={action_options}
                    onChange={(val)=> {
                        setCallAction(val.value)
                        setDisplayFeedback(true)
                    }} 
                    value={callAction}
                    placeholder="Select an option" />
            </div>
            </div>

            {displayFeedback?  
            <div>
            <div className='feedbackHeader'>Feedback</div>
            {/* className='feedbackWrapper' */}
            <div>
                    <Table
                    columns={columns}
                    data={behaviourCount}
                    loading={isPopupDataLoading}
                    selectRow = {true}
                    type = 'client'
                    selectType='multi'
                    updateRowSelect={feedbackSelected}
                    />
                {
                    cmmWarningMsg && cmmWarningMsg.length > 0 && cmmWarningMsg.map(warning => {
                        return (
                            <div className='cmm-warning-action'>
                                <img src={AlertIcon} alt='warning'/>"{warning && warning.feedback}" feedback count has reached the limit. "{capitalise(warning && warning.action)}" action will be automatically taken against the caller</div>
                        )
                    })
                }
                <input type="text" className='additionalInfo' ref={customInput} placeholder='Additional information (Press "Enter" after typing)' onKeyDown={enterPress}/>
            </div>

            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                <FrappButton 
                className="submit" 
                id="bulk-status-btn" 
                ref={submitBtn}
                handler={()=>takeCallAction()}
                disabled={callAction ? false : true}
                >
                SUBMIT
                </FrappButton>
            </div> </div>:''  } 
            </div>   
        } 

        const complianceHistory = () => {
            let formattedData = complianceHistoryTableData &&
            complianceHistoryTableData.map( i => {
                i.feedbacks = {
                    ...i.feedbacks,title:i.title
                }
                return i
            })

            const columns = [
                {
                    Header: "Project",
                    accessor: 'title',
                    Cell: (cell) => {
                        let data = cell.row.original
                        return data && data.feedbacks ?
                        data.feedbacks.title || 'NA'
                        : 'NA'
                    },
                    disableSortBy: true
                },
                //since all the other fields of these tables are coming from the same
                //accesor feedbacks, cell.row.original is being because using the same accessor multiple
                // times will give errors
                {
                    Header: 'Date',
                    Cell: (cell) => {
                       let data = cell.row.original
                       return data && data.feedbacks ?
                       moment(data.feedbacks.createdAt).format("DD/MM/YYYY"): 'NA'
                    },
                    disableSortBy: true
                },
                {
                    Header: 'Invalid Reason',
                    accessor: 'reason',
                    Cell: (cell) => {
                        let data = cell.row.original
                        if(data && data.feedbacks){
                            const string = data.feedbacks.reason
                            const reasons = string.split(";")
                            return <ul>{reasons.map((i)=>{
                                return <li>{i}</li>
                            })}</ul>
                        }else{
                            return 'NA'
                        }
                    },
                    disableSortBy: true
                },
                {
                    Header: 'Audit Type',
                    accessor: "type",
                    Cell: (cell) => {
                        let data = cell.row.original
                        return data && data.feedbacks ?
                        data.feedbacks.type == "rule2A" ? "Winning Outcome"
                        : data.feedbacks.type == "rule3A" ? "High HT" 
                        : data.feedbacks.type == "rule4A" ? "Self Disconnection" 
                        : data.feedbacks.type == "rule5A" ? "Short Calls" : 'NA'
                        : 'NA'
                    },
                    disableSortBy: true
                }
            ]

            return (
             <div className='complianceHistory'>
                <div className='tab-row'>
                <div className='first-div'>
                <TabGroup tabs={TABS} color={'green-tab-outline'} 
                    onClick={(tab) => {
                    setActiveTableComplianceHistory(tab)
                    setIsPopupDataLoading(true)
                    if (tab == TABS[0]) {
                        fetchComplianceHistoryData(activeRow.userId, 'rule2A')
                    }else if (tab == TABS[1]) {
                        fetchComplianceHistoryData(activeRow.userId, 'rule3A')
                    }else if (tab == TABS[2]) {
                        fetchComplianceHistoryData(activeRow.userId, 'rule4A')
                    }else if (tab == TABS[3]) {
                        fetchComplianceHistoryData(activeRow.userId, 'rule5A')
                    }
                    }
                } 
                activeTab={activeTableComplianceHistory}/>
                </div>
                </div>

                {/* <div className="calls-count">Winning outcome : {complianceHistoryWinningCount} | High HT : {complianceHistoryHTCount} </div> */}
                    <Table
                    columns={columns}
                    data={formattedData}
                    loading={isPopupDataLoading}
                    type = 'client'
                    height = '55'
                    />
            </div>
            )
        }

        const cmmActionHistory = () => {
            let formattedData = []
            cmmActionHistoryData && cmmActionHistoryData.map(history => {
                history.cmmActionLog && history.cmmActionLog.map(action => {
                    formattedData.push({
                        ...action,
                        title: history.title
                    })   
                })
            })

            const columns = [
                {
                    Header: "Project",
                    accessor: 'title',
                    Cell: (cell) => {
                        let data = cell.row.original
                        return data && data.title ?
                        data.title || 'NA'
                        : 'NA'
                    },
                    disableSortBy: true
                },
                {
                    Header: 'Date',
                    accessor: 'actionTakenDate',
                    Cell: (cell) => {
                       let data = cell.row.original
                       return data && data.actionTakenDate ?
                       moment(data.actionTakenDate).format("DD/MM/YYYY"): 'NA'
                    },
                    disableSortBy: true
                },
                {
                    Header: 'CMM action',
                    Cell: (cell) => {
                        let data = cell.row.original
                        return `${capitalise(data.action)} (${capitalise(data.actionType)})`
                    },
                    disableSortBy: true
                },
                {
                    Header: 'Feedback',
                    accessor: "feedback",
                    Cell: (cell) => {
                        let data = cell.row.original
                        if(data && data.feedback){
                            const feedbacks = data.feedback.split(";")
                            return <ul>{feedbacks.map((i)=>{
                            return <li key={i+1}>{i}</li>
                            })}</ul>
                        }else{
                            return 'NA'
                        }
                    },
                    disableSortBy: true
                }
            ]

            return(
                <>
                <Table
                columns={columns}
                data={formattedData}
                loading={isPopupDataLoading}
                type = 'client'
                height = '55'
                />
                </>
            )
        }

        const consequenceManagement = () => {

            let consequence_options = [
                { label: "Ban",value:"ban"},
                { label: "Warning",value:"warning"},
                { label: "Retrain",value:"retrain"}
            ]

            const handleChangeWarningCheckboxFeedback = (event) => {
                let feedback = warningFeedbackData.map((item) => {
                    if(item.label === event.target.name) { 
                        item.checked = event.target.checked
                        return item
                    }
                    return item
                })
                setWarningFeedbackData(feedback)
            };

            const renderWarningCheckboxFeedback = (checked, label, id, idx) => {
                return (
                    <label key={idx} className='option'>
                     <input type="checkbox"  name={label} checked={checked} onChange={handleChangeWarningCheckboxFeedback}/><span>{label}</span>
                    </label>
                )
            }

            const handleChangeRetrainCheckboxFeedback = (event) => {
                let manipulatedData = retrainFeedbackData.map((item) => {
                    if(item.label === event.target.name) { 
                        item.checked = event.target.checked
                        return item
                    }
                    return item
                })
                setRetrainFeedbackData(manipulatedData)
            };

            const renderRetrainCheckboxFeedback = (checked, label, id, idx) => {
                return (
                    <label key={idx} className='option'>
                     <input type="checkbox"  name={label} checked={checked} onChange={handleChangeRetrainCheckboxFeedback}/><span>{label}</span>
                    </label>
                )
            }      
            const enterPressWarning = (event) => {
                if(event.key === "Enter") {
                    
                    {setWarningFeedbackData([
                        ...warningFeedbackData,
                        {label: event.target.value, checked: true}
                        ])  
                    customInput.current.value = '' } 
                }
            }

            const enterPressRetrain = (event) => {
                if(event.key === "Enter") {
                    
                    {setRetrainFeedbackData([
                        ...retrainFeedbackData,
                        {label: event.target.value, checked: true}
                        ])  
                    customInput.current.value = '' } 
                }
            }

            const takeWarningAction = () => {
                submitBtn.current.startLoading()
                setComplianceModal(false)
                setActiveTab({
                    label: tabsForStage[0].label,
                    value: tabsForStage[0].value
                })
                resetCompliancePopupState()
                let payload = {}
                let checkSelectedFeedback = warningFeedbackData.some((item) => {
                    return item.checked === true
                })
                if (checkSelectedFeedback) { 
                    payload= {
                        userId: activeRow.userId,
                        teleprojectId: activeRow.teleprojectId,
                        reasons: warningFeedbackData.map((item) => {
                            if (item.checked === true) {
                                return item.label
                            }
                        }).filter(i => i)
                    }           
                }else{
                    payload= {
                        userId: activeRow.userId,
                        teleprojectId: activeRow.teleprojectId
                    }
                }

                if(payload){
                    ApiActions.sendConsequenceWarning(payload).then(resp => {
                        toast("Success")  
                    }).catch(err => {
                        console.error(err.response)
                        toast("Error")    
                    })
                }
            }

            const takeRetrainAction = () => {
                submitBtn.current.startLoading()
                setComplianceModal(false)
                setActiveTab({
                    label: tabsForStage[0].label,
                    value: tabsForStage[0].value
                })
                resetCompliancePopupState()
                let payload = {}
                let checkSelectedFeedback = retrainFeedbackData.some((item) => {
                    return item.checked === true
                })
                if (checkSelectedFeedback) { 
                    payload= {
                        teleproject: activeRow.teleprojectId,
                        user: activeRow.userId,
                        action: "training",
                        retrainReasons: retrainFeedbackData.map((item) => {
                            if (item.checked === true) {
                                return item.label
                            }
                        }).filter(i => i),
                        reason:"Move to training from QA Compliance"
                    }           
                }else{
                    payload= {
                        teleproject: activeRow.teleprojectId,
                        user: activeRow.userId,
                        action: "training",
                        reason:"Move to training from QA Compliance"
                    }
                }
                if(payload){
                    ApiActions.sendConsequenceRetrain(payload).then(resp => {
                        toast("Success")   
                    }).catch(err => {
                        console.error(err)
                        toast("Error")    
                    })
                }
            }

            return  <div className='consequence-popup'>
                {showCMM ? 
                    <div>
                        <div className='consequence_heading'>Caller Action</div>
                        <div className='dropdown-actions consequenceOptions'>
                            <Dropdown className='consequenceDropdown' 
                            options={consequence_options} 
                            placeholder="Select an option"
                            value={cmmAction}
                            onChange={(val)=> {
                                setCmmAction(val.value)
                            }} 
                            />
                        </div>

                        {
                            cmmAction == "ban" ? 
                            <div className='ban_message'><img src={require('../../assets/images/exclamation.svg')} style={{"marginRight":"15px"}}/>  Hi QA Manager, I would like to initiate the banning process for caller - {activeRow.name} with mobile number {activeRow.mobile} having user ID "{activeRow._id}" for the project "{activeRow.teleprojectTitle}".
                            Kindly process my request.</div> 
                            :
                            cmmAction == "warning" ? 
                            <> 
                                <div className='feedbackHeader'>Feedback</div>
                                <div className='feedbackWrapper'>
                                    {warningFeedbackData.map((item, idx) => {
                                    return renderWarningCheckboxFeedback(item.checked, item.label, item.id, idx);
                                    })}
                                    <input type="text" className='additionalInfo' ref={customInput} placeholder='Additional information (Press "Enter" after typing)' onKeyDown={enterPressWarning}/>
                                </div>
                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                    <FrappButton 
                                    className="submit" 
                                    id="bulk-status-btn" 
                                    ref={submitBtn}
                                    handler={()=>takeWarningAction()}
                                    disabled={cmmAction == "ban" ||cmmAction == ""}
                                    >
                                    SUBMIT
                                    </FrappButton>
                                </div>  
                            </> 
                            :
                            cmmAction == "retrain" ? 
                            <> 
                                <div className='feedbackHeader'>Feedback</div>
                                <div className='feedbackWrapper'>
                                    {retrainFeedbackData.map((item, idx) => {
                                    return renderRetrainCheckboxFeedback(item.checked, item.label, item.id, idx);
                                    })}
                                    <input type="text" className='additionalInfo' ref={customInput} placeholder='Additional information (Press "Enter" after typing)' onKeyDown={enterPressRetrain }/>
                                </div>
                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                    <FrappButton 
                                    className="submit" 
                                    id="bulk-status-btn" 
                                    ref={submitBtn}
                                    handler={()=>takeRetrainAction()}
                                    disabled={cmmAction == "ban" ||cmmAction == ""}
                                    >
                                    SUBMIT
                                    </FrappButton>
                                </div>  
                            </>  
                            : ''
                        }

                    </div>
                    :
                    <div>Finish the Call Action Feedback</div>
                } </div>
        }
        
        return (
            <FrappModal className="caller-audit-modal"
            show={complianceModal}
            onCloseButton={true}
            closeButtonClicked={() => {
                setComplianceModal(false)
                setActiveTab({
                    label: tabsForStage[0].label,
                    value: tabsForStage[0].value
                })
                resetCompliancePopupState()
            }}
            >
            <div className="caller-qc">
            <div className='caller-details'>{activeRow.name} | {activeRow.mobile} | Audit ID - {activeRow._id}</div>
            <CustomTab tabs={tabsForStage} setActiveTabFn={(data) => setActiveTabFn(data, activeRow)} className={'navy-blue'}/>
            {renderContent(activeTab.value, activeRow)}
            </div>
            </FrappModal>
        )
    }

    const renderDataCollectionPopup = (rowData) => {
        const customerInfo = dataCollectionData && dataCollectionData.teletask && dataCollectionData.teletask.data && Object.keys(dataCollectionData.teletask.data).length>0 ? dataCollectionData.teletask.data : ''
        const customerInfoKeys = customerInfo ? Object.keys(customerInfo) : ''
        return (
            <FrappModal
            width="500px"
            show={dataCollectionModal}
            onCloseButton={true}
            closeButtonClicked={() => {
                setDataCollectionModal(false)
                setDataCollectionData('')
            }}>
                <div className='caller-details'>{activeRow.name} | {activeRow.mobile} | Audit ID - {activeRow._id}</div>

                <div className='compliance-data-collection-popup'>
                    <div className='compliance-data-collection-header'>Data Collection</div>

                    <div><audio controls src={ dataCollectionData && dataCollectionData.recordingUrl? dataCollectionData.recordingUrl:''}/></div>

                    <div className='outcome-box'>
                        <div className='outcome-header'>Outcome marked:</div> <div> &nbsp; &nbsp;{dataCollectionData && dataCollectionData.outcome && dataCollectionData.outcome.title ? dataCollectionData.outcome.title : ''}</div>
                    </div>

                    <div className='outcome-box'>
                        <div className='outcome-header'>Disconnected By:</div> <div> {dataCollectionData && dataCollectionData.disconnectedBy ? dataCollectionData.disconnectedBy == "Caller" ?  <div style={{'color':'red'}}>&nbsp; &nbsp;Telecaller</div> : dataCollectionData.disconnectedBy=="Callee" ? <div style={{'color':'#17BCBC'}}>&nbsp; &nbsp;Customer</div> : <div style={{'color':'#555555'}}>&nbsp; &nbsp;{dataCollectionData.disconnectedBy}</div> : <div style={{'color':'#555555'}}>&nbsp; &nbsp; NA</div>}</div>
                    </div>

                    <div className='dc-subheading'>Data Collected</div>
                    <div className='compliance-data-collection-responses'>
                        <ol className='data-collection-content'>
                            {dataCollectionData && dataCollectionData.responses && dataCollectionData.responses.length>0 ? dataCollectionData.responses.map(response => {
                                return Object.keys(response).length>0? 
                                (<div className='q-and-a'><li>{response.question}
                                </li>
                                A: {response.answer}
                                </div>) : ''
                            })
                            : "Information Not Avaialable"}
                        </ol>
                    </div>

                    <div className='customer-info-header'>Customer Info</div>
                    <div className='customer-info'>
                        <ul className='customer-info-content'>{customerInfo ? 
                            customerInfoKeys.map(key => {
                                return (<li className='customer-info-line'><div className='customer-field'>{key}</div>: &nbsp;{customerInfo[key]}</li>)
                            })
                        :"Information Not Avaialable"}</ul>
                    </div>
                </div>
            </FrappModal>
        )
    }

    const fetchReasons = async (rowData) => {
        try {
            const result = await ApiActions.getQAFeedbackReason()
            if (result) {
                setCheckRetrainReasons(result.retrainReasons.map((item) => {
                    return { "label": item, "checked": false }
                }))
            }
        } catch (error) {
            console.error(error)
        }
    }

    const renderCallerAuditPopup = () => {
        const setActiveTabFn = (activeTab,activeRow) => {
            setActiveTab({
                label: activeTab.label,
                value: activeTab.value
            })
            setActiveTableComplianceHistory(activeTable)
            fetchApiCallsBasedOnStage(activeRow, activeTab)
        }

        const fetchApiCallsBasedOnStage = (activeRow, activeTab) => {
            if (activeTab.value == "compliance_history") {
                setIsPopupDataLoading(true)
                // ApiActions.getComplianceHistoryCount(activeRow.userId,'rule2A').then(resp => setComplianceHistoryWinningCount(resp.length))
                // ApiActions.getComplianceHistoryCount(activeRow.userId,'rule3A').then(resp => setComplianceHistoryHTCount(resp.length))
                fetchComplianceHistoryData(activeRow.userId,rule)
            }
            if(activeTab.value == "cmm_action_history"){
                setIsPopupDataLoading(true)
                fetchCmmActionHistory(activeRow.userId)
            }
            if(activeTab.value == "cmm"){
                fetchReasons()
            }
        }

        const fetchCmmActionHistory = (userId) => {
            return ApiActions.getCmmActionHistory({userId}).then(resp => {
                setCmmActionHistoryData(resp.data)
                setIsPopupDataLoading(false)
            })
        }

        const callerAction = () => {
            let action_options = [
                { label: "Approve", value: "approved" },
                { label: "Incorrect Handling", value: "incorrect handling" }
            ]

            //fn to change the state of checked value to true or false
            // based on the checkbox selected ot deselected
            const handleChangeCheckboxFeedback = (event) => {
                let manipulatedData = checkboxFeedbackData.map((item) => {
                    if(item.label === event.target.name) { 
                        item.checked = event.target.checked
                        return item
                    }
                    return item
                })
                setCheckboxFeedbackData(manipulatedData)
            };

            //function to add the custom checkbox to the checkbox list if users hits the 'Enter' key
            const enterPress = (event) => {
                if(event.key === "Enter") {
                    //add the custom feedback to the table
                    let temp = [...behaviourCount]
                    temp = [
                        ...temp,
                        {
                            "feedback_name": event.target.value,
                            "count": 0,
                            "rules": [],
                            "isIncreased": false
                        }
                    ]
                    setBehaviourCount(temp)
                    customInput.current.value = ''
                }
            }

            //fn to render the checkbox by passing it the label and checked attribute got from from array 
            const renderCheckboxFeedback = (checked, label, id, idx) => {
                return (
                    <label key={idx} className='option'>
                     <input type="checkbox"  name={label} checked={checked} onChange={handleChangeCheckboxFeedback}/><span>{label}</span>
                    </label>
                )
            }

            const takeCallAction = () => {
                submitBtn.current.startLoading()
                let tempCheckBox = checkboxFeedbackData.map(each => {
                    return{
                        ...each,
                        checked: false
                    }
                })
                setCheckboxFeedbackData(tempCheckBox)
                setShowCMM(true)
                // create an object called payload, this object will contain the body that needs
                // to be sent to the api
                let payload = {}
                // condition to check if atleast one checkbox was selected
                let checkSelectedFeedback = checkboxFeedbackData.some((item) => {
                    return item.checked === true
                })
                // if block executed if checkSelectedFeedback is true
                if (callActionFeedback) { 
                    payload= {
                        teletaskHistoryAuditId: activeRow._id,
                        action: callAction,
                        rule: 'rule1A',
                        feedbacks: callActionFeedback
                        // if checkSelectedFeedback is true we are adding the feedback key to the payload
                    }           
                }else{
                    // else block executed if checkSelectedFeedback is false
                    // if checkSelectedFeedback is false we will not add the feedback key to the payload
                    payload= {
                        teletaskHistoryAuditId: activeRow._id,
                        rule: 'rule1A',
                        action: callAction
                    }
                }
                // if payload is present we are hitting the api
                if(payload){
                    ApiActions.sendComplianceFeedback(payload).then(resp => {
                        toast("Success")
                        submitBtn.current.stopLoading()     
                    }).catch(err => {
                        console.error(err.response)
                        toast("Error")
                        submitBtn.current.stopLoading()      
                    })
                }
            }

            const columns = [
                {
                    Header: 'Feedback',
                    accessor: (row) => row.feedback_name,
                    width: '80%',
                    disableSortBy: true
                },
                {
                    Header: 'Count',
                    accessor: (row) => row.count,
                    width: '20%',
                    disableSortBy: true
                }
            ]

            //this function will be truggered everytime the feedback is selected or deselected to add or remove the cmm warning message
            const checkForCmmActionWarning = (rows) => {
                let warningMessage = []
                if(!rows){
                    setCmmwarningMsg(warningMessage)
                    return false
                }
                behaviourCount && behaviourCount.length > 0 && 
                Object.keys(cmmRules).map((key)=> {
                    cmmRules[key].map(rule => {
                        let getCount = rows && rows.find(ob => ob.feedback_name == key)
                        if(getCount && getCount.count >= rule.eligibility){
                            warningMessage.push({
                                action: rule.action,
                                feedback: key
                            })
                        }
                    })
                })
                if(warningMessage && warningMessage.length > 0){
                    setCmmwarningMsg([warningMessage[warningMessage.length-1]])
                }else{
                    setCmmwarningMsg([])
                }        
            }

            //this function will increase or decrease the count on the feedback if selected or deselected
            const feedbackSelected = (rows = []) => {
                if(rows && rows.length > 0){
                    behaviourCount && behaviourCount.map(obj => {
                        const findIndex = rows.find(t => t.feedback_name == obj.feedback_name)
                        if (findIndex && !obj.isIncreased){
                            obj.isIncreased = true
                            obj.count = obj.count + 1
                        }

                        if(!findIndex && obj.isIncreased){
                            obj.isIncreased = false
                            obj.count = obj.count - 1
                        }
                        checkForCmmActionWarning(rows)
                    })
                }else{
                    behaviourCount && behaviourCount.map(obj => {
                        if(obj.isIncreased){
                            obj.isIncreased = false
                            obj.count = obj.count - 1
                        }
                    })
                    checkForCmmActionWarning()
                }
                setCallActionFeedback(rows && rows.map(row => row.feedback_name))
            }

            return <div className='callActionPopup'>
                <div className='actionWrapper'>
                <div className='callActionHeader'>Call Action</div>
            <div className='dropdown-actions '>
                    <Dropdown className='callActionDropdown' 
                    options={action_options}
                    onChange={(val)=> {
                        setCallAction(val.value)
                        setDisplayFeedback(true)
                    }} 
                    value={callAction}
                    placeholder="Select an option" />
            </div>
            </div>

            {displayFeedback?  
            <div>
            <div className='feedbackHeader'>Feedback</div>
            {/* className='feedbackWrapper' */}
            <div>
                    <Table
                    columns={columns}
                    data={behaviourCount}
                    loading={isPopupDataLoading}
                    selectRow = {true}
                    type = 'client'
                    selectType='multi'
                    updateRowSelect={feedbackSelected}
                    />
                {
                    cmmWarningMsg && cmmWarningMsg.length > 0 && cmmWarningMsg.map(warning => {
                        return (
                            <div className='cmm-warning-action'>
                                <img src={AlertIcon} alt='warning'/>"{warning && warning.feedback}" feedback count has reached the limit. "{capitalise(warning && warning.action)}" action will be automatically taken against the caller</div>
                        )
                    })
                }
                <input type="text" className='additionalInfo' ref={customInput} placeholder='Additional information (Press "Enter" after typing)' onKeyDown={enterPress}/>
            </div>

            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                <FrappButton 
                className="submit" 
                id="bulk-status-btn" 
                ref={submitBtn}
                handler={()=>takeCallAction()}
                disabled={callAction ? false : true}
                >
                SUBMIT
                </FrappButton>
            </div> </div>:''  } 
            </div>   
        } 

        const cmmActionHistory = () => {
            let formattedData = []
            cmmActionHistoryData && cmmActionHistoryData.map(history => {
                history.cmmActionLog && history.cmmActionLog.map(action => {
                    formattedData.push({
                        ...action,
                        title: history.title
                    })   
                })
            })

            const columns = [
                {
                    Header: "Project",
                    accessor: 'title',
                    Cell: (cell) => {
                        let data = cell.row.original
                        return data && data.title ?
                        data.title || 'NA'
                        : 'NA'
                    },
                    disableSortBy: true
                },
                {
                    Header: 'Date',
                    accessor: 'actionTakenDate',
                    Cell: (cell) => {
                       let data = cell.row.original
                       return data && data.actionTakenDate ?
                       moment(data.actionTakenDate).format("DD/MM/YYYY"): 'NA'
                    },
                    disableSortBy: true
                },
                {
                    Header: 'CMM action',
                    Cell: (cell) => {
                        let data = cell.row.original
                        return `${capitalise(data.action)} (${capitalise(data.actionType)})`
                    },
                    disableSortBy: true
                },
                {
                    Header: 'Feedback',
                    accessor: "feedback",
                    Cell: (cell) => {
                        let data = cell.row.original
                        if(data && data.feedback){
                            const feedbacks = data.feedback.split(";")
                            return <ul>{feedbacks.map((i)=>{
                            return <li key={i+1}>{i}</li>
                            })}</ul>
                        }else{
                            return 'NA'
                        }
                    },
                    disableSortBy: true
                }
            ]

            return(
                <>
                <Table
                columns={columns}
                data={formattedData}
                loading={isPopupDataLoading}
                type = 'client'
                height = '55'
                />
                </>
            )
        }

        const complianceHistory = () => {
            let formattedData = complianceHistoryTableData &&
            complianceHistoryTableData.map( i => {
                i.feedbacks = {
                    ...i.feedbacks,title:i.title
                }
                return i
            })

            const columns = [
                {
                    Header: "Project",
                    accessor: 'title',
                    Cell: (cell) => {
                        let data = cell.row.original
                        return data && data.feedbacks ?
                        data.feedbacks.title || 'NA'
                        : 'NA'
                    },
                    disableSortBy: true
                },
                //since all the other fields of these tables are coming from the same
                //accesor feedbacks, cell.row.original is being because using the same accessor multiple
                // times will give errors
                {
                    Header: 'Date',
                    Cell: (cell) => {
                       let data = cell.row.original
                       return data && data.feedbacks ?
                       moment(data.feedbacks.createdAt).format("DD/MM/YYYY"): 'NA'
                    },
                    disableSortBy: true
                },
                {
                    Header: 'Invalid Reason',
                    accessor: 'reason',
                    Cell: (cell) => {
                        let data = cell.row.original
                        if(data && data.feedbacks){
                            const string = data.feedbacks.reason
                            const reasons = string.split(";")
                            return <ul>{reasons.map((i)=>{
                                return <li>{i}</li>
                            })}</ul>
                        }else{
                            return 'NA'
                        }
                    },
                    disableSortBy: true
                },
                {
                    Header: 'Audit Type',
                    accessor: "type",
                    Cell: (cell) => {
                        let data = cell.row.original
                        return data && data.feedbacks ?
                        data.feedbacks.type == "rule2A" ? "Winning Outcome"
                        : data.feedbacks.type == "rule3A" ? "High HT" 
                        : data.feedbacks.type == "rule4A" ? "Self Disconnection" 
                        : data.feedbacks.type == "rule5A" ? "Short Calls" : 'NA'
                        : 'NA'
                    },
                    disableSortBy: true
                }
            ]

            return (
             <div className='complianceHistory'>
                <div className='tab-row'>
                <div className='first-div'>
                <TabGroup tabs={TABS} color={'green-tab-outline'} 
                    onClick={(tab) => {
                    setActiveTableComplianceHistory(tab)
                    setIsPopupDataLoading(true)
                    if (tab == TABS[0]) {
                        fetchComplianceHistoryData(activeRow.userId, 'rule2A')
                    }else if (tab == TABS[1]) {
                        fetchComplianceHistoryData(activeRow.userId, 'rule3A')
                    }else if (tab == TABS[2]) {
                        fetchComplianceHistoryData(activeRow.userId, 'rule4A')
                    }else if (tab == TABS[3]) {
                        fetchComplianceHistoryData(activeRow.userId, 'rule5A')
                    }
                    }
                } 
                activeTab={activeTableComplianceHistory}/>
                </div>
                </div>

                {/* <div className="calls-count">Winning outcome : {complianceHistoryWinningCount} | High HT : {complianceHistoryHTCount} </div> */}
                    <Table
                    columns={columns}
                    data={formattedData}
                    loading={isPopupDataLoading}
                    type = 'client'
                    height = '55'
                    />
            </div>
            )
        }

        const consequenceManagement = () => {

            let consequence_options = [
                { label: "QC Pass", value: "qcpass" },
                { label: "Retrain", value: "retrain" }
            ]

            const renderCheckboxCallerAction = (checked, label, idx) => {
                return <label key={idx} className='option'>
                     <input type="checkbox"  name={label} checked={checked} onChange={handleChangeCheckboxCallerAction}/><span>{label}</span>
                    </label>
            }

            const handleChangeCheckboxCallerAction = (event) => {
                setCheckboxCallerAuditData(checkboxCallerAuditData.map((item) => {
                    if (item.label === event.target.name) {
                        item.checked = event.target.checked
                        return item
                    }
                    return item
                }))
            }

            const handleChangeWarningCheckboxFeedback = (event) => {
                let feedback = warningFeedbackData.map((item) => {
                    if(item.label === event.target.name) { 
                        item.checked = event.target.checked
                        return item
                    }
                    return item
                })
                setWarningFeedbackData(feedback)
            };

            const renderWarningCheckboxFeedback = (checked, label, id, idx) => {
                return (
                    <label key={idx} className='option'>
                     <input type="checkbox"  name={label} checked={checked} onChange={handleChangeWarningCheckboxFeedback}/><span>{label}</span>
                    </label>
                )
            }

            const handleChangeRetrainCheckboxFeedback = (event) => {
                let manipulatedData = retrainFeedbackData.map((item) => {
                    if(item.label === event.target.name) { 
                        item.checked = event.target.checked
                        return item
                    }
                    return item
                })
                setRetrainFeedbackData(manipulatedData)
            };

            const renderRetrainCheckboxFeedback = (checked, label, id, idx) => {
                return (
                    <label key={idx} className='option'>
                     <input type="checkbox"  name={label} checked={checked} onChange={handleChangeRetrainCheckboxFeedback}/><span>{label}</span>
                    </label>
                )
            }
            const enterPressWarning = (event) => {
                if(event.key === "Enter") {
                    
                    {setWarningFeedbackData([
                        ...warningFeedbackData,
                        {label: event.target.value, checked: true}
                        ])  
                    customInput.current.value = '' } 
                }
            }

            const enterPressRetrain = (event) => {
                if(event.key === "Enter") {
                    
                    {setRetrainFeedbackData([
                        ...retrainFeedbackData,
                        {label: event.target.value, checked: true}
                        ])  
                    customInput.current.value = '' } 
                }
            }

            const takeWarningAction = () => {
                submitBtn.current.startLoading()
                setComplianceModal(false)
                setActiveTab({
                    label: tabsForStage[0].label,
                    value: tabsForStage[0].value
                })
                resetCompliancePopupState()
                let payload = {}
                let checkSelectedFeedback = warningFeedbackData.some((item) => {
                    return item.checked === true
                })
                if (checkSelectedFeedback) { 
                    payload= {
                        userId: activeRow.userId,
                        teleprojectId: activeRow.teleprojectId,
                        reasons: warningFeedbackData.map((item) => {
                            if (item.checked === true) {
                                return item.label
                            }
                        }).filter(i => i)
                    }           
                }else{
                    payload= {
                        userId: activeRow.userId,
                        teleprojectId: activeRow.teleprojectId
                    }
                }

                if(payload){
                    ApiActions.sendConsequenceWarning(payload).then(resp => {
                        toast("Success")  
                    }).catch(err => {
                        console.error(err.response)
                        toast("Error")    
                    })
                }
            }

            const takeRetrainAction = () => {
                submitBtn.current.startLoading()
                setActiveTab({
                    label: tabsForStage[0].label,
                    value: tabsForStage[0].value
                })
                let payload = {}
                let checkSelectedFeedback = retrainFeedbackData.some((item) => {
                    return item.checked === true
                })
                // if (checkSelectedFeedback) { 
                //     payload= {
                //         teleproject: activeRow.teleprojectId,
                //         user: activeRow.userId,
                //         action: cmmAction,
                //         retrainReasons: retrainFeedbackData.map((item) => {
                //             if (item.checked === true) {
                //                 return item.label
                //             }
                //         }).filter(i => i),
                //         reason: cmmAction == "training" ? "Move to training from QA Compliance" : "Cleared in QC"
                //     }           
                // }else{
                    payload= {
                        teleproject: activeRow.teleprojectId,
                        user: activeRow.userId,
                        action: cmmAction == "retrain" ? "training" : cmmAction,
                        reason: cmmAction == "retrain" ? "Move to training from QA Compliance" : "Cleared in QC"
                    }
                // }

                if (cmmAction === "retrain") {
                    let retrainReasons = checkboxCallerAuditData.map((item) => {
                        if (item.checked === true) {
                            return item.label
                        }
                    }).filter(i => i)
                    payload = {
                        ...payload,
                        retrainReasons: retrainReasons
                    }
                }
                if(payload){
                    ApiActions.sendConsequenceRetrain(payload).then(resp => {
                        toast("Success")
                        setCallerAuditModal(false)
                        resetCallerAuditPopup()
                    }).catch(err => {
                        console.error(err)
                        toast("Error")    
                    })
                }
            }

            return  <div className='consequence-popup'>
                {showCMM ? 
                    <div>
                        <div className='consequence_heading'>Caller Action</div>
                        <div className='dropdown-actions consequenceOptions'>
                            <Dropdown className='consequenceDropdown' 
                            options={consequence_options} 
                            placeholder="Select an option"
                            value={cmmAction}
                            onChange={(val)=> {
                                setCmmAction(val.value)
                                setCheckboxCallerAuditData([...checkRetrainReasons])
                            }} 
                            />
                        </div>
                        {
                            cmmAction == "retrain" ?
                            <>
                            <div className='feedbackHeader'>Feedback</div>
                            <div className='feedbackWrapper'>
                                {checkboxCallerAuditData.map((item, idx) => {
                                return renderCheckboxCallerAction(item.checked, item.label, item.id, idx);
                                })}
                            </div>
                            </>
                            : null
                        }
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                        <FrappButton 
                        className="submit" 
                        id="bulk-status-btn" 
                        ref={submitBtn}
                        handler={()=>takeRetrainAction()}
                        disabled={cmmAction == ""}
                        >
                        SUBMIT
                        </FrappButton>
                        </div>  

                    </div>
                    :
                    <div>Finish the Call Action Feedback</div>
                } </div>
        }

        const fetchComplianceHistoryData = (userId, rule) => {
            return ApiActions.getComplianceHistoryTableData(userId,rule).then(resp => {
                setComplianceHistoryTableData(resp)
                setIsPopupDataLoading(false)
            }).catch(err => {
                console.error(err)
                setIsPopupDataLoading(false)
                toast(err && err.response ? err.response && err.response.msg : "Error");
            });
        }


        const renderContent = (activeTab) => {
            switch (activeTab) {
                case 'caller_action':
                    return callerAction()
                case 'compliance_history':
                    return complianceHistory()
                case 'cmm':
                    return consequenceManagement()
                case 'cmm_action_history':
                    return cmmActionHistory()
                default:
                    break;
            }
        }

        return (
            <FrappModal 
            // className="call-audit-modal"
            className="caller-audit-modal"
            show={callerAuditModal}
            onCloseButton={true}
            closeButtonClicked={() => {
                resetCallerAuditPopup()
                // setCallAction('')
                // setDisplayFeedback(false)
            }}>
            {/* <AuditRule1ACall row={activeRow} close={()=>setCallerAuditModal(false)}/> */}

            <div className="caller-qc">
            <div className='caller-details'>{activeRow.name} | {activeRow.mobile} | Audit ID - {activeRow._id}</div>
            <CustomTab tabs={tabsForStage} 
            setActiveTabFn={(data) => setActiveTabFn(data, activeRow)}
            // activeTab={activeTableComplianceHistory}
            className={'navy-blue'}/>
            {renderContent(activeTab.value, activeRow)}
            
            </div>

            </FrappModal>
        )
    }

    const fetchCallHistory = (data) => {
        if(data && data.teletask){
            const {teletask: {_id}} = data
            getCallHistoryApi.request(_id)
        }
    }


    const renderCallHistory = () => {
        return(
            <Popup
            show={showCallHistory}
            closePopup={()=>{
                setSelectedRowData('')
                setShowCallHistory(false)
            }}
            size='sm'
            heading={`Call history`}
            >
                {callRecordLoading ? <ThreeDotLoader/> :
                    callRecords ? <CallHistory data={
                        {...selectedRowData, 
                            callRecords: callRecords && callRecords.sort((a,b) => b.attempts - a.attempts) 
                        }} /> : <div>Call history cannot be fetched</div>
                }
            </Popup>
        )
    }

    const columns = [
        {
            Header: "Project ID",
            accessor: 'teleprojectId',
            Cell: ({value}) => ( value ? value : 'NA'),
            disableSortBy: true
        },
        {
            Header: 'Project',
            accessor: 'teleprojectTitle',
            Cell: ({value}) => ( value ? value : 'NA'),
            disableSortBy: true
        },
        {
            Header: 'Outcome',
            accessor: 'outcome',
            Cell: ({value}) => {
                return value && value.title ? value.title : "Outcome not marked"
            },
            disableSortBy: true
        },
        {
            Header: 'Caller name',
            accessor: "name",
            Cell: ({value}) => ( value ? value : 'NA'),
            disableSortBy: true
        },
        {
            Header: 'Caller mobile',
            accessor: "mobile",
            Cell: ({value}) => ( value ? value : 'NA'),
            disableSortBy: true
        },
        {
            Header: 'Caller Audio',
            accessor: "recordingUrl",
            Cell: ({value}) => (
                <audio controls src={value}/>
            ),
            disableSortBy: true
        },
        {
            Header: 'Disconnected By',
            accessor: "disconnectedBy",
            Cell: ({value}) => (
                value ? 
                value=="Caller" ? <div style={{'color':'red'}}>Telecaller</div> : 
                value == "Callee" ? <div style={{'color':'#17BCBC'}}>Customer</div> : <div style={{'color':'#555555'}}>{value}</div>
                : <div style={{'color':'#555555'}}>NA</div>
            ),
            disableSortBy: true
        },
        {
            Header: 'Audit type',
            accessor: "rule",
            Cell: ({value}) => (
                value ? 
                value.type == 'rule1A' ?  'MinHT Winning Outcome check' : 'Winning outcome sample audit' 
                : <div >NA</div>
            ),
            disableSortBy: true
        },
        {
            Header: '',
            accessor: "action",
            Cell: (cell) => (
            <div className='compliance-table-icons'>
                <OverlayTrigger placement="bottom" overlay={tooltip('Click to view the data collected')}>
                    <div onClick={() => showPopup(cell.row.original, "data-collection")}>
                        <FrappButton className="data-collection-icon"  >
                        <img src={require("../../assets/images/data-collection.png")} alt='delete-icon'/>
                        </FrappButton>
                    </div>
                </OverlayTrigger>
                <CustomTooltip placement={'bottom'} description={'Call history'}
                component={<img src={TrackHistory} width='18px'
                    alt='Call history'
                    onClick={() => {
                        fetchCallHistory(cell.row.original)
                        setSelectedRowData(cell.row.original)
                        setShowCallHistory(true)
                    }}
                />}>
                </CustomTooltip>
                <div onClick={() => showPopup(cell.row.original, cell.row.original.rule.type == 'rule1A' ? "caller_audit" : "compliance")}>
                    <FrappButton className="audit-view-btn"  >
                        View
                    </FrappButton>
                </div>
            </div>
            ),
            disableSortBy: true
        }
    ]
    
    const filterChange = (d) => {
        setSearchText(d)
        setCurrentPage(1)
    }

    const tooltip = (info) => {
        return (<Tooltip id="tooltip">
            {info}
        </Tooltip>)
    };

    return(  
        <div className="additional-wrapper">
        <Heading text={'QA Compliance'}/>

        <div className='tab-row'>
        <div className='first-div'>
        <TabGroup tabs={TABS} color={'green-tab-outline'} 
            onClick={(tab) => {
            setActiveTable(tab)
                if (tab == TABS[0]) {
                    setRule('rule2A')
                }else if (tab == TABS[1]) {
                    setRule('rule3A')
                }else if (tab == TABS[2]) {
                    setRule('rule4A')
                }else if (tab == TABS[3]) {
                    setRule('rule5A')
                }
            setCurrentPage(1)
            setSearchText('')
            filterChange('')
            }
        } 
        activeTab={activeTable}/>
        </div>
        <DropdownButton
        bsSize='medium'
        title={'Send Report'}
        id="blue-drop-down"
        >
        <MenuItem eventKey="1" onSelect={() => setReportModal({type: 'Audit Report', isOpen: true})}>Audit Report</MenuItem>
        <MenuItem eventKey="2" onSelect={() => setReportModal({type: 'CMM Action Report', isOpen: true})}>CMM Action Report</MenuItem>
        </DropdownButton>
        </div>
       
 
        {
            activeTable == 'Auto hold calls for audit' ?
            <ProjectQmf {...props}/>
            :
            <Table
            columns={rule == ('rule2A' || 'rule1A') ? columns : columns.filter(({accessor}) => accessor !== 'rule')}
            data={teleprojectsdata}
            totalCount={totalCount}
            limit={limit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            loading={isTableLoading}
            filterChange = {filterChange}
            selectRow = {false}
            isGlobalFilter = {true}
            searchBy = {'Project ID'}
            search = {searchText}
            height = '60'
            />
        }
       
        {reportModal && reportModal.isOpen ? renderReportsModal() : null}
        {complianceModal ? renderCompliancePopup() : null}
        {callerAuditModal ? renderCallerAuditPopup() : null}
        {dataCollectionModal ? renderDataCollectionPopup(): null}
        {showCallHistory ? renderCallHistory() : null}
        <ToastContainer draggable={true} />
        </div>
    )
}

export default QualityCompliance